import { Component } from 'react';
import React from 'react';
import './user-hover.css';
import { Link } from 'react-router-dom';

class UserHover extends Component {
  render() {
    return (
      <div id={this.props.id} className="userHover">
        {this.props.actions
          .filter(a => a.shouldDisplay(false))
          .map(action => (
            <Link
              className="action"
              key={action.label}
              to={action.target}
              onClick={action.action}
            >
              {action.label}
            </Link>
          ))}
      </div>
    );
  }
}

export default UserHover;