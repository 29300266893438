import { Component } from "react";
import News from "./news/news";
import Statistics from "./statistics/statistics";
import AppContext from "../app/app-context";
import { Link } from "react-router-dom";

import "./dashboard.css";

class Dashboard extends Component {
  static contextType = AppContext;
  render() {
    let translations = this.context.translations;
    return (
      <div className="dashboard">
        <News />
        <div className="dashboard-divider">
          <span>{translations.dashboard_call_to_action}</span>
          <Link to="/calculate">
            <button>{translations.general_calculate}</button>
          </Link>
        </div>
        <Statistics />
      </div>
    );
  }
}

export default Dashboard;
