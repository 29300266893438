import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import BaseComponent from "../../app/base-component";

import Popup from "../../app/popup/popup";

import "./tenant-theme-popup.css";

class TenantThemePopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      tenantTheme: {
        primaryColor: props.tenant.tenantTheme.primaryColor,
        secondaryColor: props.tenant.tenantTheme.secondaryColor,
      },
    };
  }

  onInputChange(e) {
    let key = e.target.name;
    var value = e.target.value;

    this.setState((state) => ({
      tenantTheme: {
        ...state.tenantTheme,
        [key]: value,
      },
    }));
  }

  uploadFile(e) {
    let translations = this.context.translations;
    let key = e.target.name;
    var value = e.target.files[0];

    if (!value) return;

    let data = this.preparePostData({
      ImageFile: value,
    });
    e.target.value = "";

    this.askConfirmation(
      translations.notification_are_you_sure,
      translations.warning_direct_file_upload,
    )
      .then((confirm) => {
        if (!confirm) return;

        let apiClient = new ApiClient(this.context);

        apiClient
          .call(
            "PUT",
            `/v1/TenantTheme/${this.props.tenant.tenantTheme.id}/${key}`,
            data
          )
          .then((result) => {
            if (result.ok) {
              this.context.addNotification(
                "success",
                translations.notification_upload_file_success
              );
            } else {
              throw result;
            }
          });
      });
  }

  preparePostData(obj) {
    let data = new FormData();

    for (const [k, v] of Object.entries(obj)) {
      if (v) {
        data.append(k, v);
      }
    }

    return data;
  }

  updateTheme() {
    let apiClient = new ApiClient(this.context);

    apiClient
      .call(
        "PUT",
        `/v1/TenantTheme/${this.props.tenant.tenantTheme.id}`,
        this.state.tenantTheme
      )
      .then((result) => {
        if (result.ok) {
          this.props.onDismiss();
          this.props.onCommit && this.props.onCommit();
          this.context.addNotification(
            "success",
            this.context.translations.notification_tenant_update_success
          );
        } else {
          throw result;
        }
      });
  }

  render() {
    let translations = this.context.translations;
    return (
      // TODO: I18n
      <Popup
        title={translations.general_tenant_theme}
        actions={[
          {
            label: translations.general_update,
            handler: this.updateTheme.bind(this),
            submit: true,
          },
        ]}
        onDismiss={this.props.onDismiss}
      >
        <div className="tenantSettingsPopup">
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{translations.general_primary_color}</h2>
                <input
                  name="primaryColor"
                  type="color"
                  value={this.state.tenantTheme.primaryColor}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{translations.general_secondary_color}</h2>
                <input
                  name="secondaryColor"
                  type="color"
                  value={this.state.tenantTheme.secondaryColor}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{translations.general_favicon}</h2>
                <input
                  name="UploadFavicon"
                  type="file"
                  onChange={this.uploadFile.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{translations.general_logo}</h2>
                <input
                  name="UploadLogo"
                  type="file"
                  onChange={this.uploadFile.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{translations.general_backdrop}</h2>
                <input
                  name="UploadBackground"
                  type="file"
                  onChange={this.uploadFile.bind(this)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}

export default TenantThemePopup;
