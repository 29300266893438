import './generic-page.css';

import AppContext from '../app-context';
import { Redirect } from 'react-router';
import BaseComponent from '../base-component';

class GenericPage extends BaseComponent {
  static contextType = AppContext;

  render() {
    return (
      <div className={'genericPage ' + this.props.className}>
        {!this.context.user && <Redirect to="/login" />}
        <h1 className="genericPage-title">{this.props.title}</h1>
        <div className="genericPage-mainContainer">
          <div className="genericPage-buttonContainer">
            {this.props.buttons}
          </div>
          {this.props.content}
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default GenericPage;
