import { Component } from "react";
import AppContext from "../../app-context";
import './buttons.css';

class OrdersButton extends Component {
  static contextType = AppContext;
  render() {
    let translations = this.context.translations;

    return (
      <span>
        <svg id='svgOrders' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
          <circle className='svgOrders-1' cx="17" cy="17" r="17" />
          <g transform="translate(-17.965 -18.91)">
            <rect className='svgOrders-2' width="11.602" height="1.318" transform="translate(29.032 43.502)" />
            <path className='svgOrders-3' d="M181.665,169.726H177.6a.659.659,0,1,0,0,1.318h4.065a.659.659,0,0,0,0-1.318Z" transform="translate(-144.745 -136.838)" />
            <path className='svgOrders-3' d="M184.741,257.569H177.6a.659.659,0,1,0,0,1.318h7.141a.659.659,0,0,0,0-1.318Z" transform="translate(-144.745 -221.604)" />
            <path className='svgOrders-3' d="M17.542,1.648a.66.66,0,0,0-.708.107L14.988,3.388,13.14,1.735a.659.659,0,0,0-.879,0L10.415,3.386,8.569,1.735a.659.659,0,0,0-.879,0L5.842,3.388,4,1.755a.659.659,0,0,0-1.1.494V13.982H.659A.659.659,0,0,0,0,14.642v1.6a3.146,3.146,0,0,0,3.142,3.142V18.069a1.826,1.826,0,0,1-1.824-1.824V15.3H11.646v.945a3.142,3.142,0,1,0,6.284,0v-14A.659.659,0,0,0,17.542,1.648Zm-.93,14.6a1.824,1.824,0,1,1-3.647,0v-1.6a.659.659,0,0,0-.659-.659H4.219V3.712L5.408,4.764a.659.659,0,0,0,.876,0L8.13,3.111l1.846,1.65a.659.659,0,0,0,.879,0L12.7,3.111l1.846,1.651a.659.659,0,0,0,.876,0l1.189-1.052Z" transform="translate(26 25.433)" />
          </g>
        </svg>
        <span>{translations['general_orders']}</span>
      </span>
    );
  }
}

export default OrdersButton;
