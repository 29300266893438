import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import BaseComponent from "../../app/base-component";

import Popup from "../../app/popup/popup";

import "./tenant-popup.css";

class TenantPopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    var tenant = {};

    if (props.tenant) {
      tenant = {
        name: props.tenant.name,
        email: props.tenant.email,

        address: props.tenant.address,
        postalCode: props.tenant.postalCode,
        city: props.tenant.city,
        country: props.tenant.country,
      };
    }

    this.state = {
      tenant: {
        name: "",
        email: "",

        address: "",
        postalCode: "",
        city: "",
        country: "",

        ...tenant,
      },
    };
  }

  onInputChange(e) {
    let key = e.target.name;
    var value = e.target.value;

    this.setState((state) => ({
      tenant: {
        ...state.tenant,
        [key]: value,
      },
    }));
  }

  createTenant() {
    let apiClient = new ApiClient(this.context);

    apiClient.call("POST", "/v1/Tenant", this.state.tenant).then((result) => {
      if (result.ok) {
        this.props.onDismiss();
        this.props.onCommit();
        this.context.addNotification(
          "success",
          this.context.translations.notification_tenant_create_success
        );
      } else {
        throw result;
      }
    });
  }

  updateTenant() {
    let isSelf =
      this.props.tenant && this.props.tenant.id === this.context.user.account.id;
    let apiClient = new ApiClient(this.context);

    apiClient
      .call("PUT", "/v1/Account/" + this.props.tenant.id, this.state.tenant)
      .then((result) => {
        if (result.ok) {
          this.props.onDismiss();
          this.props.onCommit();
          this.context.addNotification(
            "success",
            this.context.translations.notification_tenant_update_success
          );

          if (isSelf) {
            this.context.setUser({
              ...this.context.user,
              account: result.json,
            });
          }
        } else {
          throw result;
        }
      });
  }

  toggleTenantActive(active) {
    let translations = this.context.translations;

    this.askConfirmation(
      translations.notification_are_you_sure,
      translations.warning_tenant_activate,
    )
      .then((confirm) => {
        if (!confirm) return;

        let apiClient = new ApiClient(this.context);
        let call = active
          ? ['PUT', `/v1/Tenant/${this.props.tenant.id}/Activate`]
          : ['DELETE', `/v1/Tenant/${this.props.tenant.id}`];

        apiClient
          .call(...call)
          .then((result) => {
            if (result.ok) {
              this.props.onDismiss();
              this.props.onCommit();
              this.context.addNotification(
                "success",
                active
                  ? translations.notification_tenant_reactivate_success
                  : translations.notification_tenant_deactivate_success
              );
            } else {
              throw result;
            }
          });
      })
  }

  render() {
    let translations = this.context.translations;
    let isSelf =
      this.props.tenant && this.props.tenant.id === this.context.user.account.id;

    return (
      // TODO: I18n
      <Popup
        title={translations.general_tenant}
        actions={
          this.props.tenant
            ? [
              this.props.tenant.active && {
                label: translations.general_deactivate,
                handler: this.toggleTenantActive.bind(this, false),
                className: 'red',
                disabled: isSelf,
              },
              !this.props.tenant.active && {
                label: translations.general_reactivate,
                handler: this.toggleTenantActive.bind(this, true),
                className: 'green',
                disabled: isSelf,
              },
              {
                label: translations.general_update,
                handler: this.updateTenant.bind(this),
                submit: true,
              },
            ]
            : [
              {
                label: translations.general_save,
                handler: this.createTenant.bind(this),
                submit: true,
              },
            ]
        }
        onDismiss={this.props.onDismiss}
      >
        <div className="tenantPopup">
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{translations.general_name}</h2>
                <input
                  name="name"
                  value={this.state.tenant.name}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{translations.general_email}</h2>
                <input
                  name="email"
                  value={this.state.tenant.email}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
          </div>
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{translations.general_address}</h2>
                <input
                  name="address"
                  value={this.state.tenant.address}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{translations.general_zip_code}</h2>
                <input
                  name="postalCode"
                  value={this.state.tenant.postalCode}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{translations.general_city}</h2>
                <input
                  name="city"
                  value={this.state.tenant.city}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{translations.general_country}</h2>
                <select
                  name="country"
                  value={this.state.tenant.country}
                  onChange={this.onInputChange.bind(this)}
                >
                  <option></option>
                  {this.context.settings.countries.map((c) => (
                    <option key={c} value={c}>
                      {c}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}

export default TenantPopup;
