import { Component } from 'react';

class EditIcon extends Component {
  render() {
    return (
      <svg id="glyphicons-halflings" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path fill={this.props.color} id="lock" d="M15,8V6a3.00328,3.00328,0,0,0-3-3H8A3.00328,3.00328,0,0,0,5,6V8a2,2,0,0,0-2,2v6a2.00006,2.00006,0,0,0,2,2H15a2.00006,2.00006,0,0,0,2-2V10A2,2,0,0,0,15,8ZM13,8H7V6A1.001,1.001,0,0,1,8,5h4a1.001,1.001,0,0,1,1,1Z" />
      </svg>

    );
  }
}

export default EditIcon;