import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import BaseComponent from "../../app/base-component";

import Popup from "../../app/popup/popup";

import "./tenant-masterdata-popup.css";

class TenantMasterdataPopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      tenantSettings: {
        cuttingTechnology: props.tenant.tenantSettings.cuttingTechnology,
        overdueQuotePeriod: props.tenant.tenantSettings.overdueQuotePeriod,
      },
    };
  }

  onInputChange(e) {
    let key = e.target.name;
    var value = e.target.value;

    if (e.target.type === "file") {
      value = e.target.files[0];
      if (!value) return;

      this.setState((state) => ({
        tenantSettings: {
          ...state.tenantSettings,
        },
      }));
    }

    this.setState((state) => ({
      tenantSettings: {
        ...state.tenantSettings,
        [key]: value,
      },
    }));
  }

  syncMasterData(e) {
    let translations = this.context.translations;
    let key = e.target.name;
    var value = e.target.files[0];

    if (!value) return;

    let data = this.preparePostData({
      ExcelFile: value,
    });
    e.target.value = "";

    this.askConfirmation(
      translations.notification_are_you_sure,
      translations.warning_direct_file_import,
    )
      .then((confirm) => {
        if (!confirm) return;

        let apiClient = new ApiClient(this.context);

        apiClient
          .call(
            "PUT",
            "/v1/TenantSettings/" +
            this.props.tenant.tenantSettings.id +
            "/" +
            key,
            data
          )
          .then((result) => {
            if (result.ok) {
              this.context.addNotification(
                "success",
                translations.notification_import_file_success
              );
            } else {
              throw result;
            }
          });
      });
  }

  preparePostData(obj) {
    let data = new FormData();

    for (const [k, v] of Object.entries(obj)) {
      if (v) {
        data.append(k, v);
      }
    }

    return data;
  }

  updateTenantSettings() {
    let apiClient = new ApiClient(this.context);

    apiClient
      .call(
        "PUT",
        "/v1/TenantSettings/" + this.props.tenant.tenantSettings.id,
        this.state.tenantSettings
      )
      .then((result) => {
        if (result.ok) {
          this.props.onDismiss();
          this.props.onCommit && this.props.onCommit();
          this.context.addNotification(
            "success",
            this.context.translations.notification_client_update_success
          );
        } else {
          throw result;
        }
      });
  }

  render() {
    let translations = this.context.translations;
    return (
      // TODO: I18n
      <Popup
        title={translations.tenant_settings_title}
        actions={[
          {
            label: translations.general_update,
            handler: this.updateTenantSettings.bind(this),
            submit: true,
          },
        ]}
        onDismiss={this.props.onDismiss}
      >
        <div className="tenantSettingsPopup">
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{translations.tenant_cutting_technology}</h2>
                <input
                  name="cuttingTechnology"
                  value={this.state.tenantSettings.cuttingTechnology}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{translations.tenant_quote_period}</h2>
                <input
                  name="overdueQuotePeriod"
                  value={this.state.tenantSettings.overdueQuotePeriod}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
          </div>
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{translations.tenant_calculation_sheet}</h2>
                <input
                  name="UploadExcelCalculation"
                  type="file"
                  onChange={this.syncMasterData.bind(this)}
                ></input>
              </div>
            </div>
          </div>
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{translations.tenant_material_groups}</h2>
                <input
                  name="ImportTenantMaterialGroups"
                  type="file"
                  onChange={this.syncMasterData.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{translations.tenant_plates}</h2>
                <input
                  name="ImportTenantPlates"
                  type="file"
                  onChange={this.syncMasterData.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{translations.tenant_rates}</h2>
                <input
                  name="ImportTenantRates"
                  type="file"
                  onChange={this.syncMasterData.bind(this)}
                ></input>
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{translations.tenant_material_prices}</h2>
                <input
                  name="ImportTenantMaterials"
                  type="file"
                  onChange={this.syncMasterData.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{translations.tenant_nesting_parameters}</h2>
                <input
                  name="ImportTenantNestingParameters"
                  type="file"
                  onChange={this.syncMasterData.bind(this)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}

export default TenantMasterdataPopup;
