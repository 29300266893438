import AppContext from '../app/app-context';
import './calculation.css';
import ModelPreview from './preview/model-preview';
import PartsList from './parts-list/parts-list';
import UploadPopup from './upload-popup/upload-popup';
import QuotationPopup from './quotation-popup/quotation-popup';
import BaseComponent from '../app/base-component';
import ApiClient from '../api-client/api-client';
import ImportOrderLinePopup from './import-order-line-popup/import-order-line-popup';


class Calculation extends BaseComponent {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.state = {
      importOrderLinePopUpShown: false,
      uploadPopUpShown: false,
      quotationPopUpShown: false,
      thicknesses: [],
      parts: [],
      previewPart: null,
      account: {},
    };
  }

  componentDidMount() {
    this.getMaterials();
    this.setState({
      account: this.context.user.account,
    });
    //this.getBendConfiguration();

    document.addEventListener("keydown", this.keyDownListener.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDownListener.bind(this));
  }

  keyDownListener(e) {
    // CTRL+A (select all)
    if (e.ctrlKey && e.keyCode === 65) {
      e.preventDefault();
      e.stopPropagation();
      this.selectAllParts();
    }
  }

  // TODO: Needs to be further implemented where needed.
  getMaterials() {
    new ApiClient(this.context).call(
      'GET',
      `/v1/TenantSettings/${this.context.theme.tenantId}/TenantMaterials`
    )
      .then(response => {
        if (response.ok) {
          let thicknesses = response.json
            .reduce((result, mt) => {
              var thickness = result.find(m => m.thickness === mt.thickness);

              if (!thickness) {
                thickness = {
                  thickness: mt.thickness,
                  materials: [],
                };

                result.push(thickness);
              }

              thickness.materials.push({
                id: mt.id,
                name: mt.material.name,
              });

              return result;
            }, []);

          thicknesses.forEach(m => {
            m.materials.sort((a, b) => a.name < b.name
              ? -1
              : (a.name < b.name ? 1 : 0)
            )
          });

          thicknesses.sort((a, b) => a.thickness < b.thickness
            ? -1
            : (a.thickness > b.thickness ? 1 : 0)
          );

          this.setState({
            thicknesses: thicknesses,
          });
        }
      })
      .catch(e => {
        return e;
      });
  }

  // TODO: Needs to be further implemented where needed.
  getBendConfiguration() {
    new ApiClient(this.context).call(
      'GET',
      '/v1/WCApi/GetBendConfiguration',
    )
      .then(response => {
        console.log(response);
      })
      .catch(e => {
        return e;
      });
  }

  setPreviewPart(id) {
    let part = this.state.parts
      .filter(part => part.fileId === id)[0];

    this.setState({
      previewPart: part,
    });
  }

  selectAllParts() {
    let val = !this.state.parts.every(p => p.selected);
    let parts = this.state.parts
      .map(part => ({
        ...part,
        selected: val,
      }));

    this.setState({
      parts: parts,
    });
  }

  togglePartSelected(id, multi) {
    let parts = this.state.parts
      .map(part => ({
        ...part,
        selected: part.fileId === id
          ? !part.selected
          : multi ? part.selected : false,
      }));

    this.setState({
      parts: parts,
    });
  }

  deleteSelection() {
    this.setStateAsync(state => ({
      parts: state.parts.filter(p => !p.selected),
    }));
  }

  validationCallback() {
    this.setState({
      instantValidation: true,
    });
  }

  toggleUploadPopUp() {
    this.setState({
      uploadPopUpShown: !this.state.uploadPopUpShown
    });
  }

  //TODO: make sure that when the pop up is shown the API also calculates
  //the correct costs.
  toggleQuotationPopUp(shown = null) {
    return this.setStateAsync(state => ({
      quotationPopUpShown: typeof shown === 'boolean'
        ? shown
        : !state.quotationPopUpShown
    }));
  }

  toggleImportOrderLinePopUp() {
    this.setState({
      importOrderLinePopUpShown: !this.state.importOrderLinePopUpShown
    })
  }

  calculateCosts() {
    this.toggleQuotationPopUp(true);
  }

  convertFileToPart(file) {
    var dimensions = [];

    if (file.isThreeDimensional) {
      dimensions = [
        file.dxfFileInfo.threeDimensional.boundary.x,
        file.dxfFileInfo.threeDimensional.boundary.y,
        file.dxfFileInfo.threeDimensional.boundary.z,
      ];
    }
    else {
      dimensions = [
        file.fileInfo.basicInformation.length,
        file.fileInfo.basicInformation.width,
      ];
    }

    dimensions = dimensions
      .map(f => Math.round(f))
      .filter(s => s).join('x');
    let thickness = file.isThreeDimensional
      ? file.dxfFileInfo.threeDimensional.thickness
      : null;

    let hasBendings = file.isThreeDimensional && file.hasDxfFile && file.dxfFileInfo.threeDimensional.bendings.length > 0;
    let hasCounterSinks = file.isThreeDimensional && file.hasDxfFile && file.dxfFileInfo.threeDimensional.features.counterSinks.length > 0;

    return {
      fileId: file.fileId,
      collectionId: file.collectionId,
      dimensions: dimensions,
      fileInfo: file.fileInfo,
      dxfFileInfo: file.dxfFileInfo,
      isThreeDimensional: file.isThreeDimensional,
      hasDxfFile: file.hasDxfFile,
      dxfFileId: file.hasDxfFile
        ? file.dxfFile.fileId
        : file.fileId,
      dxfCollectionId: file.hasDxfFile
        ? file.dxfFile.collectionId
        : file.collectionId,
      selected: false,
      amount: file.isThreeDimensional && file.dxfFileInfo
        ? file.dxfFileInfo.threeDimensional.assembly.instances.length
        : 1,
      material: null,
      materialThickness: this.state.thicknesses.find(t => t.thickness === thickness),
      note: "",
      bending: hasBendings,
      hasBendings: hasBendings,
      counterSinking: hasCounterSinks,
      hasCounterSinks: hasCounterSinks,
      surfaceTreatment: "NONE",
      engraving: false,
      materialCertificate: "NONE",
    };
  }

  didReceiveParts(files) {
    this.setState({
      parts: [
        ...this.state.parts,
        ...files.map(this.convertFileToPart.bind(this))
      ]
    });
  }

  didReceiveOrderLines(data) {
    this.setState({
      parts: this.state.parts.map((part) => (
        this.mapImportToPart(data, part)
      ))
    });
  }

  mapImportToPart(data, part) {
    let importPart = data.find(e => e.name === part.fileInfo.file.name)
    let surfaceTreatmentMapping = importPart
      ? this.getSurfaceTreatmentMapping().find(s => s.label === importPart.surfaceTreatment)
      : null;
    let materialCertificateMapping = importPart
      ? this.getMaterialCertificateMapping().find(s => s.label === importPart.materialCertificate)
      : null;

    let importThickness = !part.isThreeDimensional ? importPart.thickness : part.materialThickness.thickness;
    let importMaterialThickness = this.state.thicknesses.find(tm => tm.thickness === importThickness);
    let importMaterial = importMaterialThickness && importMaterialThickness.materials.find(m => m.name === importPart.material);

    return importPart && importMaterial && importMaterialThickness
      ?
      {
        ...part,
        amount: importPart.amount,
        material: importMaterial,
        materialThickness: importMaterialThickness,
        surfaceTreatment: surfaceTreatmentMapping ?
          surfaceTreatmentMapping.tag
          : "NONE",
        engraving: importPart.engraving,
        materialCertificate: materialCertificateMapping ?
          materialCertificateMapping.tag
          : "NONE",
      }
      : part
  }

  onSettingChange(key, val) {
    this.setState({
      [key]: val,
    });
  }

  onInputChange(key, val) {
    this.setState(state => ({
      parts: [
        ...state.parts.map((part) => {
          var material = part.material;

          if (part.selected && key === 'materialThickness') {
            if (!val) {
              material = null;
            }
            else {
              material = part.material && val.materials
                .find(m => m.name === part.material.name);
            }
          }

          return {
            ...part,
            material: material,
            [key]: (part.selected
              ? val
              : part[key]),
          }
        })
      ]
    }));
  }

  render() {
    let translations = this.context.translations;
    let selectedParts = this.state.parts.filter(p => p.selected);

    return (
      <div className="pageContainer">
        <div className="calculation">
          <div className="partsListContainer">
            <span className="partsListContainer-title">
              {translations['calculate_parts_list_title']}
            </span>
            <PartsList
              account={this.state.account}
              onSettingChange={this.onSettingChange.bind(this)}
              parts={this.state.parts}
              selectedParts={selectedParts}
              instantValidation={this.state.instantValidation}
              thicknesses={this.state.thicknesses}
              toggleImportOrderLinePopUp={this.toggleImportOrderLinePopUp.bind(this)}
              toggleUploadPopUp={this.toggleUploadPopUp.bind(this)}
              togglePartSelected={this.togglePartSelected.bind(this)}
              deleteSelection={this.deleteSelection.bind(this)}
              calculateCosts={this.calculateCosts.bind(this)}
              onInputChange={this.onInputChange.bind(this)}
              setPreviewPart={this.setPreviewPart.bind(this)}
              selectAllParts={this.selectAllParts.bind(this)}
            />
          </div>
          <div className="partPreviewContainer">
            <span className="partPreviewContainer-title">
              {translations['calculate_part_model_view_title']}
            </span>
            <ModelPreview part={this.state.previewPart} />
          </div>

        </div>
        {this.state.uploadPopUpShown &&
          <UploadPopup
            onDismiss={this.toggleUploadPopUp.bind(this)}
            didReceiveParts={this.didReceiveParts.bind(this)}
          />
        }
        {this.state.quotationPopUpShown &&
          <QuotationPopup
            onDismiss={this.toggleQuotationPopUp.bind(this)}
            validationCallback={this.validationCallback.bind(this)}
            parts={this.state.parts}
            account={this.state.account}
          />
        }

        {this.state.importOrderLinePopUpShown &&
          <ImportOrderLinePopup
            onDismiss={this.toggleImportOrderLinePopUp.bind(this)}
            didReceiveOrderLines={this.didReceiveOrderLines.bind(this)}
          />
        }
      </div>
    );
  }
}

export default Calculation;