import ApiClient from '../../api-client/api-client';
import AppContext from '../../app/app-context';
import BaseComponent from '../../app/base-component';
import Loader from '../../app/loader/loader';
import { delay } from '../../helpers';

import './model-preview.css';
import ObjPreview from './obj-preview';
import SvgPreview from './svg-preview';

class ModelPreview extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      fileInfo: null,
      preview: null,
      flattenPreview: false,
    };
  }

  switchFlattenPreview(flatten) {
    this.setStateAsync((state) => ({
      fileInfo: null,
      preview: null,
      flattenPreview: flatten,
    }))
      .then(this.getFileInfo.bind(this))
      .then(this.getFilePreview.bind(this));
  }

  componentDidUpdate(prevProps) {
    let hadPart = prevProps.part;
    let hasPart = this.props.part;
    let changedPart = (hadPart !== hasPart)
      || (hadPart
        && hasPart
        && this.props.part.fileId !== prevProps.part.fileId)
    if (changedPart) {
      this.setStateAsync((state) => ({
        fileInfo: null,
        preview: null,
        flattenPreview: false,
      }))
        .then(this.getFileInfo.bind(this))
        .then(this.getFilePreview.bind(this));
    }
  }

  getFileInfo() {
    let part = this.props.part;

    if (!part) {
      return;
    }

    let flatten = this.state.flattenPreview;
    let collectionId = (flatten && part.dxfCollectionId) || part.collectionId;
    let fileId = (flatten && part.dxfFileId) || part.fileId;

    let url = '/v1/WCApi/GetFileInfo';

    return new ApiClient(this.context).call('GET', url, null, false, {}, {
      'collectionId': collectionId,
      'fileId': fileId,
    }).then(response =>
      this.setStateAsync(state => ({
        fileInfo: response.json.file
      }))
    );
  }

  getFilePreview() {
    if (!this.state.fileInfo) return;

    let part = this.props.part;

    if (!part) {
      return;
    }
    let format = this.isTwoDimensional()
      ? 'svg'
      : 'obj';
    let flatten = this.state.flattenPreview;
    let collectionId = (flatten && part.dxfCollectionId) || part.collectionId;
    let fileId = (flatten && part.dxfFileId) || part.fileId;

    let url = '/v1/WCApi/GetFilePreview';

    return new ApiClient(this.context).call('GET', url, null, false, {}, {
      collectionId: collectionId,
      fileId: fileId,
      format: format,
    }).then(response => {
      if (response.status === 206) {
        return delay(250).then(this.getFilePreview.bind(this));

      } else if (response.status === 200) {
        return this.setStateAsync((state) => ({
          preview: response.json.image
        }));
      }
    });
  }

  isTwoDimensional() {
    return this.context.settings.twoDimensional.includes(
      this.state.fileInfo.extension.toLocaleLowerCase()
    );
  }

  render() {
    let part = this.props.part;
    let fileInfo = this.state.fileInfo;
    let preview = this.state.preview;
    let translations = this.context.translations;

    let empty = !this.props.part;
    let ready = fileInfo;
    let hasObj = part && part.hasDxfFile;
    let isObj = fileInfo && !this.isTwoDimensional();

    return (
      <div className="modelPreview">
        {!empty
          ? <div className="modelPreview-title">
            <span>
              {translations['general_part']}
              : </span>
            {ready &&
              <span>{part.fileInfo.file.name}</span>
            }
          </div>
          : <span>
            {translations['calculate_part_model_view_none']}
          </span>
        }
        {part && ready && (isObj
          ? <ObjPreview source={'data:text/plain;base64,' + preview} />
          : <SvgPreview data={preview} />)
        }
        {part && !ready && <Loader />}
        {part && ready && hasObj &&
          <div className="switchContainer">
            <button
              className={`generalButton`}
              disabled={!this.state.flattenPreview}
              onClick={this.switchFlattenPreview.bind(this, false)}
            >
              3D
            </button>
            <button
              className={`generalButton`}
              disabled={this.state.flattenPreview}
              onClick={this.switchFlattenPreview.bind(this, true)}
            >
              2D
            </button>
          </div>
        }
      </div>
    );
  }
}

export default ModelPreview;