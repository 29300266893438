import ApiClient from "../../../api-client/api-client";
import AppContext from "../../../app/app-context";
import BaseComponent from "../../../app/base-component";
import Checkbox from "../../../app/checkbox/checkbox";

import "./list-item.css";
import ListSubItem from "./list-subitem";


class ListItem extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      preview: null,
    };
  }

  onCheckboxChange(e) {
    let key = e.target.id;
    let ev = e.nativeEvent;
    let multi = ev.shiftKey || ev.ctrlKey || ev.metaKey || ev.altKey;

    this.props.togglePartSelected(key, multi);
  }

  onPreviewClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.setPreviewPart(this.props.fileId);
  }

  componentDidMount() {
    this.getFilePreview();
  }

  getFilePreview() {
    let url = "/v1/WCApi/GetFilePreview";

    new ApiClient(this.context)
      .call(
        "GET",
        url,
        null,
        false,
        {},
        {
          collectionId: this.props.collectionId,
          fileId: this.props.fileId,
          format: "png",
        }
      )
      .then((response) => {
        this.setState({
          preview: response.json.image,
        });
      });
  }

  getValidationClass() {
    if (this.props.instantValidation) {
      if (this.props.material && this.props.materialThickness) {
        return 'valid';
      }

      return 'invalid';
    }

    return '';
  }

  render() {
    let translations = this.context.translations;
    let validationClass = this.getValidationClass();

    return (
      <div className="listItem">
        <div className="listItem-header">
          <Checkbox
            id={this.props.fileId}
            selected={this.props.selected}
            onChange={this.onCheckboxChange.bind(this)}
            title={translations.general_set + ": "}
            title_two={this.props.fileInfo.file.name}
          />
        </div>

        <label htmlFor={this.props.fileId} className={`listItem-main ${validationClass}`}>
          <img
            className="listItem-image"
            alt=""
            src={"data:image/png;base64," + this.state.preview}
            onClick={this.onPreviewClick.bind(this)}
          />
          <div className="listItem-main-informationContainer">
            <div className="columnLeft">
              <div>
                <span className="property">
                  {translations.general_amount}:{" "}
                </span>
                <span className="value">{this.props.amount}</span>
              </div>

              <div>
                <span className="property">
                  {translations.general_material}:{" "}
                </span>
                <span className="value">
                  {this.props.material ? this.props.material.name : ""}
                </span>
              </div>

              <div>
                <span className="property">
                  {translations.general_thickness}:{" "}
                </span>
                <span className="value">
                  {this.props.materialThickness
                    ? this.props.materialThickness.thickness
                    : 0}
                  {translations.general_mm}
                </span>
              </div>

              <div>
                <span className="property">
                  {translations.general_dimensions}:{" "}
                </span>
                <span className="value">
                  {this.props.dimensions + " " + translations.general_mm}
                </span>
              </div>
            </div>
            <div className="columnRight">
              <span className="property">
                {translations.general_operations}:{" "}
              </span>
              <div className="operationsContainer">
                {this.getPartOperations(this.props).map((operation) => {
                  return <div key={operation}>{operation}</div>;
                })}
              </div>
            </div>
          </div>
        </label>

        {this.props.subParts.map((subPart) => (
          <ListSubItem
            {...subPart}
            key={subPart.fileId}
            togglePartSelected={this.props.togglePartSelected}
            setPreviewPart={this.props.setPreviewPart}
          />
        ))}
      </div>
    );
  }
}

export default ListItem;
