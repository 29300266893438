import './table.css';

import AppContext from '../app-context';
import BaseComponent from '../base-component';
import Loader from '../loader/loader';

class Table extends BaseComponent {
  static contextType = AppContext;

  updateSort(key, map, e) {
    if (!!map.sortKey) {
      let sortAsc = `${map.sortKey}`;
      let sortDesc = `-${map.sortKey}`;

      if (this.props.sortQuery === sortAsc) {
        this.props.updateSortQuery(sortDesc);
      }
      else if (this.props.sortQuery === sortDesc) {
        this.props.updateSortQuery('');
      }
      else {
        this.props.updateSortQuery(sortAsc);
      }
    }

    e.preventDefault();
    e.stopPropagation();
  }

  renderHeaders() {
    return Object.entries(this.props.mapping).map(([k, map]) => {
      let sortable = !!map.sortKey;
      let sortAsc = this.props.sortQuery === `${map.sortKey}`;
      let sortDesc = this.props.sortQuery === `-${map.sortKey}`;
      let sortIcon = '\xa0\xa0\xa0\xa0';

      if (sortAsc) {
        sortIcon = '▲';
      }
      else if (sortDesc) {
        sortIcon = '▼';
      }

      return <span
        key={k}
        className={`dataHeader-${k} ${sortable ? 'sortable' : ''}`}
        onClick={this.updateSort.bind(this, k, map)}
      >
        {map.label}{sortIcon}
      </span>;
    });
  }

  renderEntries() {
    let selectable = !!this.props.onSelect;
    let selected = this.props.selected;
    let selectedKey = selected && (selected.id || selected.key);

    return this.props.entries.map((entry) => {
      if (!entry.id && !entry.key) {
        console.log(`Entry does not have a key!\r\n${JSON.stringify(entry)}`);
      }

      let key = entry.id || entry.key;
      let selected = key === selectedKey;
      let onSelect = selectable
        ? (e) => this.props.onSelect(entry)
        : false;

      return <div
        key={key}
        className={`dataEntry ${selectable && 'selectable'} ${selected && 'selected'}`}
        onClick={onSelect}
      >
        {this.renderProperties(entry)}
      </div>;
    });
  }

  renderProperties(entry) {
    return Object.entries(this.props.mapping).map(([k, map]) => {
      let v = map.value
        ? map.value(entry)
        : entry[k];
      let title = typeof v === 'string'
        ? v
        : map.label;

      return <span key={k} className={'dataProperty dataProperty-' + k} title={title}>{v}</span>;
    })
  }

  render() {
    let className = this.props.className || '';

    if (!Array.isArray(this.props.entries)) return <Loader />

    return <div className={`wcTable ${className}`}>
      <div className="dataHeader">
        {this.renderHeaders()}
      </div>
      {this.renderEntries()}
    </div>;
  }
}

export default Table;
