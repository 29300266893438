import { Component } from 'react';

class SaveIcon extends Component {
  render() {
    return (
      <svg id="glyphicons-halflings" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path fill={this.props.color} id="lock-open" d="M17,10v6a2.00006,2.00006,0,0,1-2,2H5a2.00006,2.00006,0,0,1-2-2V10A2,2,0,0,1,5,8h8V4a1.001,1.001,0,0,0-1-1H8A1.001,1.001,0,0,0,7,4V5H5V4A3.00328,3.00328,0,0,1,8,1h4a3.00328,3.00328,0,0,1,3,3V8A2,2,0,0,1,17,10Z" />
      </svg>
    );
  }
}

export default SaveIcon;