import React from "react";
import AppContext from "../../../app/app-context";
import BaseComponent from "../../../app/base-component";
import ApiClient from "../../../api-client/api-client";

import Popup from "../../../app/popup/popup";
import Table from "../../../app/table/table";

import "./calculation-overview.css";
import Loader from "../../../app/loader/loader";

class CalculationOverview extends BaseComponent {
  static contextType = AppContext;

  constructor(props, context) {
    super(props);

    let plates = this.props.quote.parts.flatMap(part =>
      part.calculatePriceResult.nestingPlates.map(plate => ({
        ...plate,
        key: plate.svgFileId,
        nestingJobId: part.calculatePriceResult.nestingJobId,
        name: plate.svgFileId,
        material: part.material.materialName,
        thickness: part.material.thickness,
      })
      ))
      .filter((v, i, s) => s.findIndex(e => e.name === v.name) === i);

    let price = {
      'SLOW': props.quote.price.slowPrice,
      'STANDARD': props.quote.price.standardPrice,
      'FAST': props.quote.price.fastPrice,
    }[props.quote.shippingTime];

    this.state = {
      selectedPlate: null,
      preview: null,
      total: price,
      plates: plates,
      calculations: props.quote.parts.map(
        (p) => ({
          ... {
            'SLOW': p.calculatePriceResult.slowCalculationResult,
            'STANDARD': p.calculatePriceResult.calculationResult,
            'FAST': p.calculatePriceResult.fastCalculationResult,
          }[props.quote.shippingTime],
          amount: p.amount,
        })
      ),
    };
  }

  componentDidMount() {
    this.onSelectPlate(this.state.plates[0]);
  }

  onSelectPlate(plate) {
    this.setStateAsync({
      preview: null,
      selectedPlate: plate,
    }).then(() => {
      this.getPreview(plate);
    });
  }

  getPreview(plate) {
    let url = "/v1/WCApi/GetFilePreview";

    new ApiClient(this.context)
      .call(
        "GET",
        url,
        null,
        false,
        {},
        {
          collectionId: plate.nestingJobId,
          fileId: plate.svgFileId,
        }
      )
      .then((response) => {
        this.setState({
          preview: response.json.image,
        });
      });
  }

  download() {
    let quote = this.props.quote;
    let translations = this.context.translations;

    if (!quote.zipFile) {
      this.context.addNotification("error", translations.notifications_download_unavailable);
      return;
    }

    this.context.addNotification("info", translations.notifications_download_started);

    new ApiClient(this.context)
      .call("GET", quote.zipFile.filePath)
      .then((response) => {
        this.apiResponseAsDownload(response, `${quote.number}.zip`);
      });
  }

  render() {
    let translations = this.context.translations;
    let formatter = new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
    });
    let sumCalcProp = (k) =>
      this.state.calculations.reduce((a, c) => c[k] * c.amount + a, 0);
    let formatSumCalcProp = (k) => {
      let v = sumCalcProp(k);

      return v > 0 ? formatter.format(v) : "-";
    };

    return (
      <Popup title={"Calculation overview"} onDismiss={this.props.onDismiss}>
        <div className="popup-section calculationOverview-container">
          <div className="nestingPreview">
            <h2>{translations.quotation_nesting_model_view_title}</h2>
            <div className="border plates">
              <Table
                mapping={{
                  name: {
                    label: "Plate name",
                  },
                  material: {
                    label: "Material",
                  },
                  thickness: {
                    label: "Thickness",
                  },
                  repeats: {
                    label: "Amount",
                  },
                  size: {
                    label: "Size",
                    value: (p) =>
                      `${Math.round(p.length)} x ${Math.round(p.width)}`,
                  },
                  efficiency: {
                    label: "Efficiency",
                    value: (p) =>
                      `${Math.round((p.areas.used / p.areas.total) * 100)}%`,
                  },
                }}
                entries={this.state.plates}
                selected={this.state.selectedPlate}
                onSelect={this.onSelectPlate.bind(this)}
              />
            </div>
            <div className="border preview">
              {this.state.preview ? (
                <img
                  alt=""
                  src={
                    "data:image/svg+xml;base64," +
                    encodeURIComponent(this.state.preview)
                  }
                />
              ) : (
                <Loader />
              )}
            </div>
          </div>
          <div className="priceSpecification">
            <h2>{translations.quotation_sales_price_specification_title}</h2>
            <div className="border specification">
              <div>{translations.quotation_material_costs}</div>
              <div>{formatSumCalcProp("materialPrice")}</div>
              <div>Certificates</div>
              <div>{formatSumCalcProp("materialCertificatePrice")}</div>
              <div>{translations.quotation_cutting_costs}</div>
              <div>{formatSumCalcProp("cuttingTechnologyPrice")}</div>
              <div>Engraving</div>
              <div>{formatSumCalcProp("engravingPrice")}</div>
              <div>Drilling</div>
              <div>{formatSumCalcProp("drillingPrice")}</div>
              <div>Tapping</div>
              <div>{formatSumCalcProp("tappingPrice")}</div>
              <div>Countersinking</div>
              <div>{formatSumCalcProp("counterSinkingPrice")}</div>
              <div>Bending</div>
              <div>{formatSumCalcProp("bendingPrice")}</div>
              <div>Surface treatment</div>
              <div>{formatSumCalcProp("surfaceTreatmentPrice")}</div>
              <div>Coating</div>
              <div>{formatSumCalcProp("coatingPrice")}</div>
              <div className="divider"></div>
              <div className="total">{translations.quotation_total}</div>
              <div className="total">{formatter.format(this.state.total)}</div>
            </div>
            <button
              type="button"
              className="popup-popupButton"
              onClick={this.download.bind(this)}
            >
              {translations["general_download"]}
            </button>
          </div>
        </div>
      </Popup>
    );
  }
}

export default CalculationOverview;
