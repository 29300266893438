import { Component } from 'react';

import './arrow.css';

class Arrow extends Component {
    render() {
        return (
            this.props.visible &&
            <div className={this.props.orientation} onClick={this.props.onClick}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.53 20.896">
                    <path d="M20.9,2.107A2.093,2.093,0,0,0,17.33.621L10.458,7.5,3.587.621A2.1,2.1,0,1,0,.621,3.587l8.358,8.337a2.093,2.093,0,0,0,2.96,0L20.3,3.587A2.093,2.093,0,0,0,20.9,2.107Z" transform="translate(-0.006 20.903) rotate(-90)" fill="#fff" />
                </svg>
            </div>
        );
    }
}

export default Arrow;