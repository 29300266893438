import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import BaseComponent from "../../app/base-component";

import Popup from "../../app/popup/popup";

import "./account-popup.css";

class AccountPopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    var account = {};

    if (props.account) {
      account = {
        name: props.account.name,
        email: props.account.email,

        address: props.account.address,
        postalCode: props.account.postalCode,
        city: props.account.city,
        country: props.account.country,

        vatPercentage: props.account.vatPercentage,
        vatNumber: props.account.vatNumber,
      };
    }

    this.state = {
      account: {
        name: "",
        email: "",

        address: "",
        postalCode: "",
        city: "",
        country: "",

        vatPercentage: "",
        vatNumber: "",

        ...account,
      },
    };
  }

  onInputChange(e) {
    let key = e.target.name;
    var value = e.target.value;

    this.setState((state) => ({
      account: {
        ...state.account,
        [key]: value,
      },
    }));
  }

  createAccount() {
    let apiClient = new ApiClient(this.context);

    apiClient
      .call(
        "POST",
        `/v1/Tenant/${this.props.tenantId}/Account`,
        this.state.account
      )
      .then((result) => {
        if (result.ok) {
          this.props.onDismiss();
          this.props.onCommit && this.props.onCommit();
          this.context.addNotification(
            "success",
            this.context.translations.notification_create_account_success
          );
        } else {
          throw result;
        }
      });
  }

  updateAccount() {
    let isSelf = this.props.account
      && this.props.account.id === this.context.user.account.id;

    let apiClient = new ApiClient(this.context);
    let url = isSelf
      ? `/v1/Account/UpdateCurrent`
      : `/v1/Account/${this.props.account.id}`;

    apiClient
      .call('PUT', url, this.state.account)
      .then((result) => {
        if (result.ok) {
          this.props.onDismiss();
          this.props.onCommit && this.props.onCommit();
          this.context.addNotification(
            'success',
            this.context.translations.notification_update_account_success
          );

          if (isSelf) {
            this.context.setUser({
              ...this.context.user,
              account: result.json,
            });
          }
        } else {
          throw result;
        }
      });
  }
  toggleAccountActive(active) {
    let translations = this.context.translations;

    this.askConfirmation(
      translations.notification_are_you_sure,
      translations.warning_account_activate,
    )
      .then((confirm) => {
        if (!confirm) return;

        let apiClient = new ApiClient(this.context);
        let call = active
          ? ['PUT', `/v1/Account/${this.props.account.id}/Activate`]
          : ['DELETE', `/v1/Account/${this.props.account.id}`];

        apiClient
          .call(...call)
          .then((result) => {
            if (result.ok) {
              this.props.onDismiss();
              this.props.onCommit();
              this.context.addNotification(
                "success",
                active
                  ? translations.notification_account_reactivate_success
                  : translations.notification_account_deactivate_success
              );
            } else {
              throw result;
            }
          });
      })
  }

  render() {
    let isSelf = this.props.account
      && this.props.account.id === this.context.user.account.id;
    let translations = this.context.translations;
    return (
      // TODO: I18n
      <Popup
        title="Account"
        actions={
          this.props.account
            ? [
              this.props.account.active && {
                label: translations.general_deactivate,
                handler: this.toggleAccountActive.bind(this, false),
                className: 'red',
                disabled: isSelf,
              },
              !this.props.account.active && {
                label: translations.general_reactivate,
                handler: this.toggleAccountActive.bind(this, true),
                className: 'green',
                disabled: isSelf,
              },
              {
                label: translations.general_update,
                handler: this.updateAccount.bind(this),
                submit: true,
              },
            ]
            : [
              {
                label: translations.general_save,
                handler: this.createAccount.bind(this),
                submit: true,
              },
            ]
        }
        onDismiss={this.props.onDismiss}
      >
        <div className="accountPopup">
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{translations.general_name}</h2>
                <input
                  name="name"
                  value={this.state.account.name}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{translations.general_email}</h2>
                <input
                  name="email"
                  value={this.state.account.email}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
          </div>
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{translations.general_address}</h2>
                <input
                  name="address"
                  value={this.state.account.address}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{translations.general_zip_code}</h2>
                <input
                  name="postalCode"
                  value={this.state.account.postalCode}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{translations.general_city}</h2>
                <input
                  name="city"
                  value={this.state.account.city}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{translations.general_country}</h2>
                <select
                  name="country"
                  value={this.state.account.country}
                  onChange={this.onInputChange.bind(this)}
                >
                  <option></option>
                  {this.context.settings.countries.map((c) => (
                    <option key={c} value={c}>
                      {c}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{translations.general_vat}</h2>
                <input
                  name="vatPercentage"
                  type="number"
                  value={this.state.account.vatPercentage}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{translations.general_vat_number}</h2>
                <input
                  name="vatNumber"
                  value={this.state.account.vatNumber}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}

export default AccountPopup;
