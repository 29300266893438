import moment from "moment";
import { Component } from "react";
import AppContext from "../../../app/app-context";
import { Line } from "react-chartjs-2";

import "./chart.css";

class Chart extends Component {
  static contextType = AppContext;
  render() {
    let translations = this.context.translations;
    let values =
      this.props.statistic &&
      this.props.statistic.map((month) => month.count).reverse();
    let labels =
      this.props.statistic &&
      this.props.statistic
        .map((month) => moment(month.month).format("MMM")[0])
        .reverse();
    let app = document.querySelector(".app");
    let primaryColor = app ? app.style.getPropertyValue("--primary-color") : "";
    let secondaryColor = app
      ? app.style.getPropertyValue("--secondary-color")
      : "";

    let data = {
      labels: labels,
      datasets: [
        {
          data: values,
          backgroundColor: "#D5E1FF",
          borderColor: primaryColor,
          fill: true,
          tension: 0.4,
          pointBorderColor: secondaryColor,
          pointBackgroundColor: "#fff",
          pointBorderWidth: 2,
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderWidth: 2,
          pointHoverRadius: 5,
        },
      ],
    };

    let options = {
      scales: {
        y: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
          min: 0,
        },
        x: {
          grid: {
            drawTicks: false,
            color: "#E2E9F0",
          },
          ticks: {
            font: {
              size: 12,
              weight: 700,
              family: "'Karla', serif",
            },
            color: primaryColor,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      elements: {
        point: {
          radius: 5,
        },
      },
    };

    return (
      <div className="chart">
        <span className="chartTitle">{translations.general_quotes}</span>
        <Line data={data} options={options} />
      </div>
    );
  }
}

export default Chart;
