import React from "react";
import AppContext from "../../app/app-context";
import BaseComponent from "../../app/base-component";
import ApiClient from "../../api-client/api-client";

import Popup from "../../app/popup/popup";
import Loader from "../../app/loader/loader";

import "./quotation-popup.css";
import QuotationListItem from "./quotation-list-item/quotation-list-item";
import CalculationOverview from "./calculation-overview/calculation-overview";
import { buildFormData } from "../../helpers";

class QuotationPopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props, context) {
    super(props);

    this.state = {
      disableButtons: true,
      informationPopupShown: false,
      quote: null,
      quoteId: '',
      parts: null,
      accounts: [],
      contacts: [],
    };
  }

  componentDidMount() {
    if (!this.props.quote) {
      this.calculatePrice();
    } else {
      this.getCalculation()
        .then(() => {
          if (this.props.recalculate) {
            this.recalculateQuote();
          }
        });
    }

    this.getAccounts();
  }

  getAccounts() {
    if (this.context.userHasClaim('Accounts', 'View')) {
      return new ApiClient(this.context)
        .call(
          "GET",
          `/v1/Tenant/${this.context.theme.tenantId}/Account`
        )
        .then((response) => {
          let accounts = response.json;

          return this.setStateAsync({
            accounts: accounts,
          });
        });
    }
    else {
      return this.setStateAsync({
        accounts: [
          this.context.user.account
        ],
      });
    }
  }

  getContacts() {
    if (this.context.userHasClaim('Contacts', 'View')) {
      return new ApiClient(this.context)
        .call(
          "GET",
          `/v1/Account/${this.state.quote.account.id}/Contact`,
          null, false, {}, {
          'Filters': 'Active==true',
        },
        )
        .then((response) => {
          let contacts = response.json;

          if (response.ok) {
            return this.setStateAsync({
              contacts: contacts,
            });
          }
          else {
            return this.setStateAsync({
              contacts: [],
            });
          }
        });
    }
    else {
      return this.setStateAsync({
        contacts: [
          this.context.user
        ],
      });
    }
  }

  getCalculation(quoteId = null) {
    let quote = this.props.quote;
    quoteId = quoteId || quote.id;
    let url = `/v1/Quote/${quoteId}`;

    return new ApiClient(this.context)
      .call("GET", url)
      .then((response) => {

        if (response.status === 204) {
          setTimeout(this.getCalculation.bind(this, quoteId), 1000);
        } else {
          let data = response.json;

          this.props.refreshData && this.props.refreshData();

          return this.mapCalculatePriceResult(data);
        }
      });
  }

  calculatePrice() {
    let url = "/v1/WCApi/CalculatePrice";
    let data = new FormData();
    let parts = this.props.parts
      .filter(part => part.dxfFileInfo !== undefined)
      .map(part => {
        return {
          collectionId: part.collectionId,
          fileId: part.fileId,
          materialThicknessId: part.material && part.material.id,
          amount: part.amount,
          bending: part.bending,
          counterSinking: part.counterSinking,
          surfaceTreatment: part.surfaceTreatment,
          engraving: part.engraving,
          materialCertificate: part.materialCertificate,
          partFile: part.partFile,
        };
      });

    buildFormData(data, parts, 'files');
    buildFormData(data, this.props.account.id, 'accountId');

    new ApiClient(this.context)
      .call("POST", url, data)
      .then((response) => {
        if (response.ok) {
          let data = response.json;

          this.getCalculation(data.id);
        } else {
          this.props.validationCallback(data);
          this.props.onDismiss();
        }
      });
  }

  mapCalculatePriceResult(data) {
    let translations = this.context.translations;

    return this.setStateAsync({
      quote: data,
      quoteId: data.id,
      parts: data.parts.map((part) => {
        var dimensions = [];
        let dxfFileInfo = part.calculatePriceResult.dxfFileInfo;

        if (dxfFileInfo.threeDimensional) {
          dimensions = [
            dxfFileInfo.threeDimensional.boundary.x,
            dxfFileInfo.threeDimensional.boundary.y,
            dxfFileInfo.threeDimensional.boundary.z,
          ];
        } else {
          dimensions = [
            dxfFileInfo.basicInformation.length,
            dxfFileInfo.basicInformation.width,
          ];
        }

        dimensions = dimensions
          .map(f => Math.round(f))
          .filter((s) => s).join("x");

        return {
          ...part,
          material: part.material.materialName,
          thickness: part.material.thickness + " " + translations.general_mm,
          dimensions: dimensions + " " + translations.general_mm,
          qualities: [],
        };
      }),
    }).then(() => {
      this.getContacts();
    });
  }

  updatePart(fileId, e) {
    let key = e.target.name;
    let val = e.target.value;

    this.setState({
      parts: [
        ...this.state.parts.map((part) => ({
          ...part,
          [key]:
            part.calculatePriceResult.file.fileId === fileId ? val : part[key],
        })),
      ],
    });
  }

  deletePart(fileId, e) {
    return this.setStateAsync((state) => ({
      parts: state.parts.filter(
        (p) => p.calculatePriceResult.file.fileId !== fileId
      ),
    }));
  }

  createQuoteRequestModel() {
    let quoteRequestModel = {
      id: this.state.quoteId,
      title: this.state.quote.title,
      description: this.state.quote.description,
      includeShippingWorks: this.state.quote.includeShippingWorks === "true",
      shippingTime: this.state.quote.shippingTime,
      discount: this.state.quote.discount,
      accountId: this.state.quote.account.id,
      contactId: this.state.quote.contact.id,
      parts: this.state.parts
        .map(part => ({
          id: part.id,
          note: part.note,
          amount: part.amount,
        })),
    };
    return quoteRequestModel;
  }

  saveToQuotes() {
    let quoteRequestModel = this.createQuoteRequestModel();
    quoteRequestModel.status = "OPEN";

    let apiClient = new ApiClient(this.context);

    apiClient
      .call("PUT", "/v1/Quote/" + this.state.quoteId, quoteRequestModel)
      .then((result) => {
        if (result.ok) {
          this.props.onDismiss();
          this.props.refreshData && this.props.refreshData();
          this.context.addNotification(
            "success",
            this.context.translations.notification_save_quotation_success
          );
        } else {
          throw result;
        }
      });
  }

  placeOrder() {
    let translations = this.context.translations;

    this.askConfirmation(
      '',
      translations.warning_ordering_quote,
      translations.general_order_button,
    )
      .then(confirmed => {
        if (!confirmed) return;

        let quoteRequestModel = this.createQuoteRequestModel();
        quoteRequestModel.status = "ORDERED";

        let apiClient = new ApiClient(this.context);

        apiClient
          .call("PUT", "/v1/Quote/" + this.state.quoteId, quoteRequestModel)
          .then((result) => {
            if (result.ok) {
              this.props.onDismiss();
              this.props.refreshData && this.props.refreshData();
              this.context.addNotification(
                "success",
                this.context.translations.notification_order_quotation_success
              );
              if (this.props.refreshData) {
                this.props.refreshData();
              }
            } else {
              throw result;
            }
          });
      });
  }

  recalculateQuote() {
    if (!this.state.quote) return Promise.resolve();

    let quoteRequestModel = this.createQuoteRequestModel();
    let apiClient = new ApiClient(this.context);

    return this.setStateAsync({
      quote: null
    })
      .then(() => {
        return apiClient
          .call("PUT", `/v1/WCApi/CalculatePrice/${this.state.quoteId}`, quoteRequestModel)
          .then((result) => {
            if (result.ok) {
              this.getCalculation(this.state.quoteId);

              // this.context.addNotification(
              //   "success",
              //   this.context.translations.notification_recalculate_success
              // );
            } else {
              throw result;
            }
          });
      })

  }

  toggleInformationPopup() {
    this.setState({
      informationPopupShown: !this.state.informationPopupShown,
    });
  }

  updateQuoteProperty(key, val) {
    let isAccount = key === 'account';

    this.setStateAsync(state => ({
      quote: {
        ...state.quote,
        contact: isAccount ? {} : state.quote.contact,
        [key]: val,
      },
    }))
      .then(() => {
        if (isAccount) {
          this.getContacts();
        }
      });;
  }

  onSelectChange(options, fallback, e) {
    let key = e.target.name;
    let id = e.target.value;
    let val = options.find(o => o.id === id) || fallback;

    this.updateQuoteProperty(key, val);
  }

  onInputChange(e) {
    let key = e.target.name;
    var val = e.target.value;

    if (key === "discount") {
      let cost = this.getManufacturingCostInCents() / 100;
      val = Math.max(0, Math.min(cost, val));
    }

    this.updateQuoteProperty(key, val);
  }

  downloadPdf() {
    let quote = this.props.quote;
    let translations = this.context.translations;

    if (!quote.pdfFile) {
      this.context.addNotification("error", translations.notifications_download_unavailable);
      return;
    }

    this.context.addNotification("info", translations.notifications_download_started);

    new ApiClient(this.context)
      .call("GET", quote.pdfFile.filePath)
      .then((response) => {
        this.apiResponseAsDownload(response, `${quote.number}.pdf`);
      });
  }

  getDiscountInCents() {
    return Math.round(this.state.quote.discount * 100);
  }

  getManufacturingCostInCents() {
    let price = {
      'SLOW': this.state.quote.price.slowPrice,
      'STANDARD': this.state.quote.price.standardPrice,
      'FAST': this.state.quote.price.fastPrice,
    }[this.state.quote.shippingTime];

    return Math.round(price * 100) || 0;
  }

  getDiscountedPriceInCents() {
    return Math.round(this.getManufacturingCostInCents() - this.getDiscountInCents());
  }

  getTotalInCents() {
    return Math.round(this.getDiscountedPriceInCents() + this.getVatInCents());
  }

  getVatPercentage() {
    let percentage = (this.state.quote
      && this.state.quote.account.vatPercentage)
      || 0;

    return percentage;
  }

  getVatInCents() {
    return Math.round(this.getDiscountedPriceInCents() * this.getVatPercentage() / 100);
  }

  renderButtons() {
    let translations = this.context.translations;
    let isOrder = !!this.props.quote && !!this.props.quote.order;
    let needsRecalculate = this.state.quote.status === 'OVERDUE'
      || this.state.quote.parts.some(originalPart => {
        let currentPart = this.state.parts
          .find(part => part.id === originalPart.id) || {};

        return currentPart.amount !== originalPart.amount;
      });

    return <>
      {!isOrder && needsRecalculate && (
        <button
          type="button"
          className="popup-popupButton"
          onClick={this.recalculateQuote.bind(this)}
        >
          {translations["quotation_recalculate_button"]}
        </button>
      )}
      {!isOrder && !needsRecalculate && (
        <button
          type="submit"
          className="popup-popupButton"
          onClick={this.saveToQuotes.bind(this)}
        >
          {translations["quotation_save_to_quotes_button"]}
        </button>
      )}
      {!isOrder && !needsRecalculate && (
        <button
          type="button"
          className="popup-popupButton"
          onClick={this.placeOrder.bind(this)}
        >
          {translations["quotation_place_order_button"]}
        </button>
      )}
      {this.props.order && (
        <button
          type="button"
          className="popup-popupButton"
          onClick={this.downloadPdf.bind(this)}
        >
          {translations["general_download_pdf"]}
        </button>
      )}
    </>;
  }

  render() {
    let translations = this.context.translations;
    let user = this.context.user;
    let isPurchaseManager = user && user.role.name === "PurchaseManager";
    let isOrder = !!this.props.quote && !!this.props.quote.order;
    let currencyFormatter = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' });

    return (
      <Popup
        title={this.state.quote && `${translations["quotation_title"]}: ${this.state.quote.number}`}
        onDismiss={this.props.onDismiss}
      // headerItem={
      //   <div className="quotationPopup-header">
      //     <h2>
      //       {translations['general_status']}:
      //     </h2>
      //     <Dropdown></Dropdown>
      //   </div>
      // }
      >
        {!this.state.quote
          ? <Loader />
          :
          <div className="quotationPopup-container">
            <div className="quotationPopup-informationContainer">
              <div className="quotationPopup-informationContainer-accountContainer">
                <h2>{translations["quotation_account_information_title"]}</h2>
                <div className="horizontalLine"></div>
                <div className="popup-inputContainer smallWidth">
                  <select
                    disabled={true}
                    name="account"
                    onChange={this.onSelectChange.bind(this, this.state.accounts, {})}
                    value={this.state.quote.account.id}>
                    <option
                      key={0xDEADBEEF}
                    >
                    </option>
                    {this.state.accounts.map(account =>
                      <option
                        key={account.id}
                        value={account.id}
                      >
                        {account.name}
                      </option>
                    )}
                  </select>
                  <select
                    disabled={isOrder}
                    name="contact"
                    onChange={this.onSelectChange.bind(this, this.state.contacts, {})}
                    value={this.state.quote.contact.id}>
                    <option
                      key={0xDEADBEEF}
                    >
                    </option>
                    {this.state.contacts.map(option =>
                      <option
                        key={option.id}
                        value={option.id}
                      >
                        {option.fullName}
                      </option>
                    )}
                  </select>
                </div>
                <div className="popup-inputContainer fullWidth">
                  <input
                    disabled={isOrder}
                    name="title"
                    value={this.state.quote.title}
                    onChange={this.onInputChange.bind(this)}
                    placeholder="Project Reference"
                  ></input>
                </div>
                {this.state.quote.contact &&
                  <div className="accountInformationContainer">
                    <div className="accountInformationContainer-rowContainer">
                      <div className="accountInformationContainer-information">
                        {translations["general_name"]}:
                      </div>
                      <div className="accountInformationContainer-data">
                        {this.state.quote.contact.fullName}
                      </div>
                    </div>
                    <div className="accountInformationContainer-rowContainer">
                      <div className="accountInformationContainer-information">
                        {translations["general_address"]}:
                      </div>
                      <div className="accountInformationContainer-data">
                        {this.state.quote.contact.address}
                      </div>
                    </div>
                    <div className="accountInformationContainer-rowContainer">
                      <div className="accountInformationContainer-information">
                        {translations["general_country"]}:
                      </div>
                      <div className="accountInformationContainer-data">
                        {this.state.quote.contact.country}
                      </div>
                    </div>
                    <div className="accountInformationContainer-rowContainer">
                      <div className="accountInformationContainer-information">
                        {translations["general_vat"]}:
                      </div>
                      <div className="accountInformationContainer-data">
                        {`${this.getVatPercentage()}%`}
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="quotationPopup-informationContainer-shippingContainer">
                <h2>{translations["quotation_shipping_title"]}</h2>
                <div className="horizontalLine"></div>
                <div className="quotationPopup-informationContainer-shippingContainer-row popup-inputContainer smallWidth">
                  <select
                    disabled={isPurchaseManager || isOrder}
                    name="includeShippingWorks"
                    value={this.state.quote.includeShippingWorks}
                    onChange={this.onInputChange.bind(this)}
                  >
                    <option value="false">
                      {translations["quotation_excl_works"]}
                    </option>
                    <option value="true">
                      {translations["quotation_incl_works"]}
                    </option>
                  </select>
                  <select
                    disabled={isOrder}
                    name="shippingTime"
                    value={this.state.quote.shippingTime}
                    onChange={this.onInputChange.bind(this)}
                  >
                    <option value="SLOW">
                      {translations.quotation_delivery_slow}
                    </option>
                    <option value="STANDARD">
                      {translations.quotation_delivery_standard}
                    </option>
                    <option value="FAST">
                      {translations.quotation_delivery_fast}
                    </option>
                  </select>
                </div>
                <h2>{translations["quotation_description"]}</h2>
                <div className="horizontalLine"></div>
                <div className="popup-inputContainer fullWidth">
                  <input
                    disabled={isOrder}
                    name="description"
                    value={this.state.quote.description}
                    onChange={this.onInputChange.bind(this)}
                    placeholder={translations["quotation_note_text"] + "..."}
                  ></input>
                </div>
              </div>
              <div className="quotationPopup-informationContainer-calculationContainer">
                <div className="informationPopupContainer">
                  {!isPurchaseManager &&
                    <button
                      className="informationPopupContainer-button"
                      onClick={this.toggleInformationPopup.bind(this)}
                    >
                      <svg
                        id="informationButton"
                        data-name="Component 57 – 8"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 10 10"
                      >
                        <circle
                          id="Ellipse_2"
                          data-name="Ellipse 2"
                          cx="5"
                          cy="5"
                          r="5"
                          fill="#306bff"
                        />
                        <text
                          id="i"
                          transform="translate(6 7)"
                          fill="#fff"
                          fontSize="7"
                          fontFamily="Karla-Bold, Karla"
                          fontWeight="700"
                        >
                          <tspan x="-2.296" y="0">
                            i
                          </tspan>
                        </text>
                      </svg>
                    </button>
                  }
                </div>
                <div>
                  <span className="calculationContainer-information">
                    {translations["quotation_manufacturing"]}:
                  </span>
                  <span className="calculationContainer-data">
                    {currencyFormatter.format(this.getManufacturingCostInCents() / 100)}
                  </span>
                </div>
                {((isPurchaseManager && this.state.quote.discount > 0) ||
                  !isPurchaseManager) && (
                    <div>
                      <span className="calculationContainer-information">
                        {translations["quotation_discount"]}:
                      </span>
                      <span className="calculationContainer-data">
                        <span className="inputPrefix">€ </span>
                        <input
                          name="discount"
                          type="number"
                          step="0.01"
                          disabled={isPurchaseManager}
                          value={this.state.quote.discount}
                          onChange={this.onInputChange.bind(this)}
                        ></input>
                      </span>
                    </div>
                  )}
                <div>
                  <span className="calculationContainer-information">
                    {translations["general_vat"]}:
                  </span>
                  <span className="calculationContainer-data">
                    {currencyFormatter.format(this.getVatInCents() / 100)}
                  </span>
                </div>
                <div className="horizontalLine"></div>
                <div>
                  <span className="calculationContainer-information">
                    {translations["quotation_total"]}:
                  </span>
                  <span className="calculationContainer-data">
                    {currencyFormatter.format(this.getTotalInCents() / 100)}
                  </span>
                </div>

                <div className="calculationContainer-buttonContainer-desktop">
                  {this.renderButtons()}
                </div>
              </div>
            </div>
            <div className="quotationPopup-partsContainer">
              <div className="quotationPopup-partsContainer-headerContainer">
                <h2 className="quotationPopup-partsContainer-parts">
                  {translations["quotation_part_and_specification_title"]}
                </h2>
                <h2 className="quotationPopup-partsContainer-price">
                  {translations["general_price"]}
                </h2>
                <h2 className="quotationPopup-partsContainer-amount">
                  {translations["general_amount"]}
                </h2>
                <h2 className="quotationPopup-partsContainer-total">
                  {translations["quotation_total"]}
                </h2>
              </div>
              <div className="horizontalLine"></div>
              <div className="quotationPopup-partListContainer">
                {this.state.parts &&
                  this.state.parts.map((part) => (
                    <QuotationListItem
                      {...part}
                      key={part.calculatePriceResult.file.fileId}
                      quote={this.state.quote}
                      isOrder={isOrder}
                      updatePart={this.updatePart.bind(
                        this,
                        part.calculatePriceResult.file.fileId
                      )}
                      deletePart={this.deletePart.bind(
                        this,
                        part.calculatePriceResult.file.fileId
                      )}
                    />
                  ))}
                {!this.state.parts && <Loader />}
              </div>
            </div>
            <div className="calculationContainer-buttonContainer-mobile">
              {this.renderButtons()}
            </div>
          </div>
        }

        {this.state.informationPopupShown && (
          <CalculationOverview
            onDismiss={this.toggleInformationPopup.bind(this)}
            quote={this.state.quote}
          />
        )}
      </Popup>
    );
  }
}

export default QuotationPopup;
