import AppContext from "../app/app-context";
import BaseComponent from "../app/base-component";
import ApiClient from "../api-client/api-client";

import GenericPage from "../app/generic-page/generic-page";
import Table from "../app/table/table";

import AddButton from "../app/generic-page/buttons/add-button";
import FilterButton from "../app/generic-page/buttons/filter-button";

import AccountPopup from "./account-popup/account-popup";

import "./account-management-page.css";
import { Link } from "react-router-dom";
import FilterPopup from "../app/table/filter-popup/filter-popup";

class AccountManagementPage extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      accountPopupShown: false,
      filterPopupShown: false,
      accounts: null,
      selectedAccount: null,
      filterQuery: 'Active==true',
    };
  }

  updateFilterQuery(filterQuery) {
    this.setStateAsync({
      filterQuery,
    })
      .then(this.refreshData.bind(this));
  }

  updateSortQuery(sortQuery) {
    this.setStateAsync({
      sortQuery
    })
      .then(this.refreshData.bind(this));
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData() {
    var sortQuery = this.state.sortQuery || '';
    var filterQuery = this.state.filterQuery || '';

    new ApiClient(this.context)
      .call("GET", `/v1/Tenant/${this.props.tenantId}/Account`, null, false, {}, {
        'Sorts': sortQuery,
        'Filters': filterQuery,
      })
      .then((response) => {
        if (response.ok) {
          let accounts = response.json;

          this.setStateAsync((state) => ({
            accounts: accounts,
          }));
        }
      });
  }

  toggleAddAccountPopup() {
    this.setState((state) => ({
      accountPopupShown: !state.accountPopupShown,
      selectedAccount: null,
    }));
  }

  toggleEditAccountPopup(account) {
    this.setState((state) => ({
      accountPopupShown: !state.accountPopupShown,
      selectedAccount: account,
    }));
  }

  toggleFilterPopup() {
    this.setState((state) => ({
      filterPopupShown: !state.filterPopupShown,
    }));
  }

  renderButtons() {
    return (
      <div>
        <FilterButton onClick={this.toggleFilterPopup.bind(this)} />
        <AddButton onClick={this.toggleAddAccountPopup.bind(this)} />
      </div>
    );
  }

  renderTable() {
    let translations = this.context.translations;

    return (
      <Table
        mapping={{
          active: {
            label: translations.general_active,
            sortKey: 'Active',
            value: (tenant) => tenant.active ? '✅' : '⛔',
          },
          account: {
            label: translations.general_account,
            value: (account) => account.name,
            sortKey: 'Name',
          },
          actions: {
            label: this.context.translations.general_actions,
            value: (account) => (
              <Link to={`/tenant/${this.props.tenantId}/account/${account.id}/contacts`}>
                <button>
                  {this.context.translations.general_contacts}
                </button>
              </Link>
            ),
          },
        }}
        entries={this.state.accounts}
        onSelect={this.toggleEditAccountPopup.bind(this)}

        sortQuery={this.state.sortQuery}
        updateSortQuery={this.updateSortQuery.bind(this)}
      />
    );
  }

  render() {
    let translations = this.context.translations;

    return (
      <GenericPage
        className="accountManagementPage"
        title="Accounts"
        buttons={this.renderButtons()}
        content={this.renderTable()}
      >
        {this.state.accountPopupShown && (
          <AccountPopup
            onDismiss={this.toggleAddAccountPopup.bind(this)}
            account={this.state.selectedAccount}
            tenantId={this.props.tenantId}
            onCommit={this.refreshData.bind(this)}
          />
        )}
        {this.state.filterPopupShown && (
          <FilterPopup
            onDismiss={this.toggleFilterPopup.bind(this)}
            updateFilterQuery={this.updateFilterQuery.bind(this)}
            filterQuery={this.state.filterQuery}
            mapping={{
              'Name': {
                label: translations.general_account,
              },
            }}
          />
        )}
      </GenericPage>
    );
  }
}

export default AccountManagementPage;
