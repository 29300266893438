import { Component } from 'react';

class ExitIcon extends Component {
  render() {
    return (
      <svg id="glyphicons-halflings" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path fill={this.props.color} id="menu-close" d="M16.35345,14.23242a.99984.99984,0,0,1,0,1.41406l-.7069.707a1.00023,1.00023,0,0,1-1.41419,0L10,12.12109,5.76764,16.35352a1.00023,1.00023,0,0,1-1.41419,0l-.7069-.707a.99984.99984,0,0,1,0-1.41406L7.87891,10,3.64655,5.76758a.99984.99984,0,0,1,0-1.41406l.7069-.707a1.00023,1.00023,0,0,1,1.41419,0L10,7.87891l4.23236-4.23243a1.00023,1.00023,0,0,1,1.41419,0l.7069.707a.99984.99984,0,0,1,0,1.41406L12.12109,10Z" />
      </svg>
    );
  }
}

export default ExitIcon;