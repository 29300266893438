import { Component } from "react";
import { Link } from "react-router-dom";
import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import Popup from "../../app/popup/popup";
import AuthenticationPage from "../authentication-page";

class LoginPage extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
    };
  }

  onInputChange(e) {
    let key = e.target.name;
    this.setState({
      [key]: e.target.value,
    });
  }

  login() {
    new ApiClient(this.context)
      .call(
        "POST",
        "/connect/token",
        {
          username: `${this.context.theme.tenantId}\\${this.state.email}`,
          password: this.state.password,
          client_id: this.context.settings.apiClient,
          client_secret: this.context.settings.apiSecret,
          grant_type: "password",
        },
        true
      )
      .then((result) => {
        if (result.ok) {
          let json = result.json;
          this.getUserData(json["access_token"]);
        }
      });
  }

  getUserData(bearerToken) {
    new ApiClient(this.context)
      .call("GET", "/v1/Contact/GetCurrent", null, false, {
        Authorization: "Bearer " + bearerToken,
      })
      .then((result) => {
        if (result.ok) {
          let user = result.json;

          this.props
            .setUser({
              ...user,
              bearerToken: bearerToken,
            })
            .then(() => {
              this.context.addNotification(
                "success",
                this.context.translations.notifications_login_successfull
              );
            });
        }
      });
  }

  render() {
    let translations = this.context.translations;

    return (
      <AuthenticationPage>
        <Popup
          title={translations["login_title"]}
          dismissable={true}
          actions={[
            {
              label: translations["password_request_submit"],
              handler: this.login.bind(this),
              submit: true,
            },
          ]}
        >
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{translations["login_email_label"]}</h2>
                <input
                  placeholder={translations["login_email_placeholder"]}
                  name="email"
                  value={this.state.email}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{translations["login_password_label"]}</h2>
                <input
                  placeholder={translations["login_password_placeholder"]}
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-linkContainer">
                <Link to="/password-request">
                  {translations["login_forgot_password"]}
                </Link>
              </div>
            </div>
          </div>
        </Popup>
      </AuthenticationPage>
    );
  }
}

export default LoginPage;
