import AppContext from "../app/app-context";
import BaseComponent from "../app/base-component";
import ApiClient from "../api-client/api-client";

import GenericPage from "../app/generic-page/generic-page";
import Table from "../app/table/table";

import AddButton from "../app/generic-page/buttons/add-button";
import FilterButton from "../app/generic-page/buttons/filter-button";

import TenantPopup from "./tenant-popup/tenant-popup";
import TenantMasterdataPopup from "./tenant-masterdata-popup/tenant-masterdata-popup";
import TenantThemePopup from "./tenant-theme-popup/tenant-theme-popup";
import TenantDomainPopup from "./tenant-domain-popup/tenant-domain-popup";

import "./tenant-management-page.css";
import { Link } from "react-router-dom";
import FilterPopup from "../app/table/filter-popup/filter-popup";

class TenantManagementPage extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      tenants: null,
      selectedTenant: null,
      filterQuery: 'Active==true',
    };
  }

  updateFilterQuery(filterQuery) {
    this.setStateAsync({
      filterQuery,
    })
      .then(this.refreshData.bind(this));
  }

  updateSortQuery(sortQuery) {
    this.setStateAsync({
      sortQuery
    })
      .then(this.refreshData.bind(this));
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData() {
    var sortQuery = this.state.sortQuery || '';
    var filterQuery = this.state.filterQuery || '';

    new ApiClient(this.context).call("GET", "/v1/Tenant", null, false, {}, {
      'Sorts': sortQuery,
      'Filters': filterQuery,
    }).then((response) => {
      if (response.ok) {
        let tenants = response.json;

        this.setStateAsync((state) => ({
          tenants: tenants,
        }));
      }
    });
  }

  togglePopup(prop, tenant, e) {
    this.setState((state) => ({
      [prop]: !state[prop],
      selectedTenant: tenant,
    }));

    e && e.preventDefault();
    e && e.stopPropagation();
  }

  toggleFilterPopup() {
    this.setState((state) => ({
      filterPopupShown: !state.filterPopupShown,
    }));
  }

  renderButtons() {
    return (
      <div>
        <FilterButton
          onClick={this.togglePopup.bind(this, "filterPopupShown", null)}
        />
        <AddButton
          onClick={this.togglePopup.bind(this, "tenantPopupShown", null)}
        />
      </div>
    );
  }

  renderTable() {
    let translations = this.context.translations;

    return (
      <Table
        mapping={{
          active: {
            label: translations.general_active,
            sortKey: 'Active',
            value: (tenant) => tenant.active ? '✅' : '⛔',
          },
          tenant: {
            label: translations.general_tenant,
            value: (tenant) => tenant.name,
            sortKey: 'Name',
          },
          actions: {
            label: translations.general_actions,
            value: (tenant) => (
              <span>
                <Link to={`/tenant/${tenant.id}/accounts`}>
                  <button>
                    {translations.general_accounts}
                  </button>
                </Link>
                &emsp;
                <button
                  onClick={this.togglePopup.bind(this, "themePopupShown", tenant)}
                >
                  {translations.general_theme}
                </button>
                &emsp;
                <button
                  onClick={this.togglePopup.bind(
                    this,
                    "masterDataPopupShown",
                    tenant
                  )}
                >
                  {translations.general_stem_data}
                </button>
                &emsp;
                <button
                  onClick={this.togglePopup.bind(
                    this,
                    "domainPopupShown",
                    tenant
                  )}
                >
                  {translations.general_domain}
                </button>
              </span>
            ),
          }
        }}
        entries={this.state.tenants}
        onSelect={this.togglePopup.bind(this, "tenantPopupShown")}

        sortQuery={this.state.sortQuery}
        updateSortQuery={this.updateSortQuery.bind(this)}
      />
    );
  }

  render() {
    return (
      <GenericPage
        className="tenantManagementPage"
        title="Tenants"
        buttons={this.renderButtons()}
        content={this.renderTable()}
      >
        {this.state.tenantPopupShown && (
          <TenantPopup
            onDismiss={this.togglePopup.bind(this, "tenantPopupShown", null)}
            tenant={this.state.selectedTenant}
            onCommit={this.refreshData.bind(this)}
          />
        )}
        {this.state.themePopupShown && (
          <TenantThemePopup
            onDismiss={this.togglePopup.bind(this, "themePopupShown", null)}
            tenant={this.state.selectedTenant}
            onCommit={this.refreshData.bind(this)}
          />
        )}
        {this.state.masterDataPopupShown && (
          <TenantMasterdataPopup
            onDismiss={this.togglePopup.bind(
              this,
              "masterDataPopupShown",
              null
            )}
            tenant={this.state.selectedTenant}
            onCommit={this.refreshData.bind(this)}
          />
        )}
        {this.state.domainPopupShown && (
          <TenantDomainPopup
            onDismiss={this.togglePopup.bind(this, "domainPopupShown", null)}
            tenant={this.state.selectedTenant}
            onCommit={this.refreshData.bind(this)}
          />
        )}
        {this.state.filterPopupShown && (
          <FilterPopup
            onDismiss={this.toggleFilterPopup.bind(this)}
            updateFilterQuery={this.updateFilterQuery.bind(this)}
            filterQuery={this.state.filterQuery}
            mapping={{
              'Name': {
                label: this.context.translations.general_tenant,
              },
            }}
          />
        )}
      </GenericPage>
    );
  }
}

export default TenantManagementPage;
