import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import BaseComponent from "../../app/base-component";

import Popup from "../../app/popup/popup";

import "./contact-popup.css";

class ContactPopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      contact: {
        fullName: "",
        account: {},
        address: "",
        postalCode: "",
        city: "",
        country: "",
        email: "",
        role: {},
        ...(props.contact || {}),
        userLanguage: (props.contact && props.contact.userLanguage) || {},
      },
    };
  }

  componentDidMount() {
    if (!this.props.contact) {
      this.setStateAsync(state => {
        let account = this.props.accounts
          .find(a => a.id === this.props.accountId);

        return {
          contact: {
            ...state.contact,
            account: account,
            address: account.address,
            postalCode: account.postalCode,
            city: account.city,
            country: account.country,
          },
        };
      });
    }
  }

  updateContact(create = false) {
    let isSelf = this.props.contact
      && this.props.contact.id === this.context.user.id;

    let apiClient = new ApiClient(this.context);
    let call = create
      ? ['POST', '/v1/Contact']
      : (isSelf
        ? ['PUT', `/v1/Contact/UpdateCurrent`]
        : ['PUT', `/v1/Contact/${this.state.contact.id}`]);

    let account = this.state.contact.account;
    let role = this.state.contact.role;
    let userLanguage = this.state.contact.userLanguage;

    apiClient
      .call(...call, {
        ...this.state.contact,
        accountId: account && account.id,
        roleId: role && role.id,
        userLanguageId: userLanguage && userLanguage.id,
        bearerToken: null,
      })
      .then((result) => {
        if (result.ok) {
          this.props.onDismiss();
          this.props.onCommit && this.props.onCommit();
          this.context.addNotification(
            "success",
            create
              ? this.context.translations.notifications_add_user_successfull
              : this.context.translations.notifications_edit_user_successfull,
          );

          if (isSelf) {
            this.context.setUser({
              ...result.json,
              bearerToken: this.context.user.bearerToken,
            });
          }
        } else {
          throw result;
        }
      });
  }

  toggleContactActive(active) {
    let translations = this.context.translations;

    this.askConfirmation(
      translations.notification_are_you_sure,
      translations.warning_contact_activate,
    )
      .then((confirm) => {
        if (!confirm) return;

        let apiClient = new ApiClient(this.context);
        let call = active
          ? ['PUT', `/v1/Contact/${this.props.contact.id}/Activate`]
          : ['DELETE', `/v1/Contact/${this.props.contact.id}`];

        apiClient
          .call(...call)
          .then((result) => {
            if (result.ok) {
              this.props.onDismiss();
              this.props.onCommit();
              this.context.addNotification(
                "success",
                active
                  ? translations.notification_contact_reactivate_success
                  : translations.notification_contact_deactivate_success
              );
            } else {
              throw result;
            }
          });
      })
  }

  onPropChange(key, val) {
    this.setState({
      contact: {
        ...this.state.contact,
        [key]: val,
      },
    });
  }

  onInputChange(e) {
    let key = e.target.name;
    let val = e.target.value;

    this.onPropChange(key, val);
  }

  onSelectChange(options, e) {
    let key = e.target.name;
    let id = e.target.value;
    let val = options.find(o => o.id === id) || {};

    this.onPropChange(key, val);
  }

  render() {
    let translations = this.context.translations;
    let isSelf = this.props.contact
      && this.props.contact.id === this.context.user.id;

    return (
      // TODO: I18n
      <Popup
        title={translations.general_contacts}
        actions={
          this.props.contact
            ? [
              this.props.contact.active && {
                label: translations.general_deactivate,
                handler: this.toggleContactActive.bind(this, false),
                className: 'red',
                disabled: isSelf,
              },
              !this.props.contact.active && {
                label: translations.general_reactivate,
                handler: this.toggleContactActive.bind(this, true),
                className: 'green',
                disabled: isSelf,
              },
              {
                label: translations.general_update,
                handler: this.updateContact.bind(this, false),
                submit: true,
              },
            ]
            : [
              {
                label: translations.general_save,
                handler: this.updateContact.bind(this, true),
                submit: true,
              },
            ]
        }
        onDismiss={this.props.onDismiss}
      >
        <div className="contactPopup">
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{translations.general_name}</h2>
                <input
                  name="fullName"
                  value={this.state.contact.fullName}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{translations.general_address}</h2>
                <input
                  name="address"
                  value={this.state.contact.address}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{translations.general_country}</h2>
                <select
                  name="country"
                  value={this.state.contact.country}
                  onChange={this.onInputChange.bind(this)}
                >
                  <option></option>
                  {this.props.countries.map((c) => (
                    <option key={c} value={c}>
                      {c}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{translations.account_zip_code_city_title}</h2>
                <input
                  className="contactPopup-inputZipCode"
                  name="postalCode"
                  value={this.state.contact.postalCode}
                  onChange={this.onInputChange.bind(this)}
                ></input>
                <input
                  name="city"
                  value={this.state.contact.city}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{translations.general_email}</h2>
                <input
                  className="contactPopup-inputEmail"
                  name="email"
                  value={this.state.contact.email}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{translations.general_language}</h2>
                <select
                  name="userLanguage"
                  value={this.state.contact.userLanguage.id}
                  onChange={this.onSelectChange.bind(this, this.props.languages)}
                >
                  <option></option>
                  {this.props.languages.map((l) => (
                    <option key={l.id} value={l.id}>
                      {l.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {!isSelf && this.props.roles.length > 0 && (
            <div className="popup-section">
              <div className="popup-column">
                <h2>{translations.general_role}</h2>
                <div className="popup-linkContainer popup-inputContainer">
                  <select
                    name="role"
                    value={this.state.contact.role.id}
                    onChange={this.onSelectChange.bind(this, this.props.roles)}
                  >
                    <option></option>
                    {this.props.roles.map((r) => (
                      <option key={r.id} value={r.id}>
                        {r.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>
      </Popup>
    );
  }
}

export default ContactPopup;
