import moment from "moment";

import AppContext from "../app/app-context";
import BaseComponent from "../app/base-component";
import ApiClient from "../api-client/api-client";

import GenericPage from "../app/generic-page/generic-page";
import Table from "../app/table/table";

import FilterButton from "../app/generic-page/buttons/filter-button";

import QuotationPopup from "../calculation/quotation-popup/quotation-popup";

import "../quote-management/quote-management-page.css";
import QuoteStateSelector from "../quote-management/quote-state-selector/quote-state-selector";
import FilterPopup from "../app/table/filter-popup/filter-popup";

class OrderManagementPage extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      quotePopupShown: false,
      filterPopupShown: false,
      quotes: null,
      selectedQuote: null,
      accounts: [],
    };
  }

  updateFilterQuery(filterQuery) {
    this.setStateAsync({
      filterQuery,
    }).then(this.refreshData.bind(this));
  }

  updateSortQuery(sortQuery) {
    this.setStateAsync({
      sortQuery,
    }).then(this.refreshData.bind(this));
  }

  componentDidMount() {
    this.getOrderStates()
      .then(() => this.getAccounts())
      .then(() => this.refreshData());
  }

  getOrderStates() {
    return this.setStateAsync({
      orderStates: [
        {
          tag: "ORDERED",
          label: "Ordered",
        },
        {
          tag: "ACCEPTED",
          label: "Accepted",
          theme: "green",
          disabled: !this.context.userHasClaim("Orders", "Edit")
        },
      ],
    });
  }

  getAccounts() {
    return new ApiClient(this.context)
      .call("GET", `/v1/Tenant/${this.context.theme.tenantId}/Account`)
      .then((response) => {
        if (response.ok) {
          let accounts = response.json;

          this.setStateAsync((state) => ({
            accounts: accounts,
          }));
        }
      });
  }

  refreshData() {
    var sortQuery = this.state.sortQuery || "";
    var filterQuery = this.state.filterQuery || "";

    return new ApiClient(this.context)
      .call(
        "GET",
        "/v1/Order",
        null,
        false,
        {},
        {
          Sorts: sortQuery,
          Filters: filterQuery,
        }
      )
      .then((response) => {
        if (response.ok) {
          let quotes = response.json;

          this.setStateAsync((state) => ({
            quotes: quotes,
          }));
        }
      });
  }

  toggleEditQuotePopup(quote) {
    this.setState((state) => ({
      quotePopupShown: !state.quotePopupShown,
      selectedQuote: quote,
    }));
  }

  toggleFilterPopup() {
    this.setState((state) => ({
      filterPopupShown: !state.filterPopupShown,
    }));
  }

  renderButtons() {
    return (
      <div>
        <FilterButton onClick={this.toggleFilterPopup.bind(this)} />
      </div>
    );
  }

  renderTable() {
    let translations = this.context.translations;
    var filteredList = this.state.quotes
      ? this.state.quotes.filter(
        (quote) =>
          !this.state.titleFilter ||
          quote.title
            .toLowerCase()
            .indexOf(this.state.titleFilter.toLowerCase()) !== -1
      )
      : null;

    return (
      <Table
        mapping={{
          number: {
            label: translations.order_order_number,
          },
          account: {
            label: translations.general_client,
            value: (quote) => quote.account.name,
          },
          contact: {
            label: translations.general_contact,
            value: (quote) => quote.contact.fullName,
          },
          title: {
            label: translations.order_project_reference,
          },
          createdAt: {
            label: translations.general_date,
            value: (quote) => moment(quote.createdAt).format("DD-MM-YYYY"),
          },
          price: {
            label: translations.general_price,
            value: (quote) => "€ " + Math.round(quote.price * 100) / 100,
          },
          status: {
            label: translations.general_status,
            value: (quote) => this.renderStatus(quote),
          },
          actions: {
            label: translations.general_actions,
            value: (quote) => this.renderActions(quote),
          },
        }}
        entries={filteredList}
        onSelect={(quote) => this.toggleEditQuotePopup(quote)}
        sortQuery={this.state.sortQuery}
        updateSortQuery={this.updateSortQuery.bind(this)}
      />
    );
  }

  updateStatus(quote, status) {
    let apiClient = new ApiClient(this.context);

    apiClient
      .call("PUT", "/v1/Quote/" + quote.id + "/OrderStatus", { status: status })
      .then((result) => {
        if (result.ok) {
          this.context.addNotification(
            "success",
            this.context.translations.notification_order_saved_success
          );
        } else {
          throw result;
        }
      })
      .then(() => {
        this.refreshData();
      });
  }

  renderStatus(quote) {
    return (
      <QuoteStateSelector
        disabled={!this.context.userHasClaim("Orders", "Edit")}
        value={quote.order.status}
        mapping={this.state.orderStates}
        onUpdateStatus={this.updateStatus.bind(this, quote)}
      />
    );
  }

  renderActions(quote) {
    return (
      <div>
      </div>
    );
  }

  render() {
    let translations = this.context.translations;
    return (
      <GenericPage
        className="quoteManagementPage"
        title={translations.general_orders}
        buttons={this.renderButtons()}
        content={this.renderTable()}
      >
        {this.state.filterPopupShown && (
          <FilterPopup
            onDismiss={this.toggleFilterPopup.bind(this)}
            filterQuery={this.state.filterQuery}
            updateFilterQuery={this.updateFilterQuery.bind(this)}
            mapping={{
              Number: {
                label: translations.quotes_quotes_number,
              },
              AccountId: {
                label: translations.general_client,
                options: this.state.accounts.map((a) => ({
                  label: a.name,
                  value: a.id,
                })),
              },
              Title: {
                label: translations.quotes_project_reference,
              },
              CreatedAt: {
                label: translations.general_date,
              },
              OrderStatus: {
                label: translations.general_status,
                options: this.state.orderStates.map((m) => ({
                  label: m.label,
                  value: m.tag,
                })),
              },
              Active: null,
            }}
          />
        )}
        {this.state.quotePopupShown && (
          <QuotationPopup
            onDismiss={this.toggleEditQuotePopup.bind(this)}
            quote={this.state.selectedQuote}
            order={true}
          />
        )}
      </GenericPage>
    );
  }
}

export default OrderManagementPage;
