import { Component } from 'react';

import './notification.css';

class Notification extends Component {
  remove() {
    this.props.removeNotification(this.props.notification.key);
  }

  render() {
    var progress = this.props.notification.timeRemaining / this.props.notification.time * 100
    return (
      <div className={"notification " + this.props.notification.severity} onClick={this.remove.bind(this)} >
        {this.props.notification.message}
        <div className="progress" style={{ right: progress + "%" }}>
        </div>
      </div>
    );
  }
}


export default Notification;