import { Component } from "react";
import AppContext from "../../app-context";

import Popup from "../../popup/popup";

import "./filter-popup.css";

class FilterPopup extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    let filters = (props.filterQuery || '')
      .split(',').reduce((a, f) => {
        let e = f.split(/==|@=\*/);
        let k = e[0];
        let v = e[1];

        a[k] = v;
        return a;
      }, {});

    this.state = {
      ...filters,
    };
  }

  getMapping() {
    return {
      Active: {
        label: this.context.translations.general_active,
        options: [
          {
            label: this.context.translations.general_yes,
            value: true
          },
          {
            label: this.context.translations.general_no,
            value: false
          }
        ]
      },
      ...this.props.mapping,
    };
  }

  onInputChange(e) {
    let key = e.target.name;
    let val = e.target.value || null;

    this.setState({
      [key]: val,
    });
  }

  saveFilter() {
    let filterQuery = Object.entries(this.getMapping())
      .filter(([k, map]) => map)
      .map(([k, map]) => {
        let op = map.options
          ? '=='
          : '@=*';
        let val = this.state[k];

        if (!val || val.trim() === '') {
          return null;
        }

        return `${k}${op}${val}`;
      })
      .filter(f => f);

    this.props.updateFilterQuery(filterQuery.join(','));
    this.props.onDismiss();
  }

  clearFilter() {
    this.props.updateFilterQuery('');
    this.props.onDismiss();
  }

  renderFilter(k, map) {
    if (map.options) {
      return <select
        name={k}
        value={this.state[k]}
        onChange={this.onInputChange.bind(this)}
      >
        <option></option>
        {map.options.map((o) => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>;
    }
    else {
      return <input
        name={k}
        value={this.state[k]}
        onChange={this.onInputChange.bind(this)}
      ></input>
    }

  }

  renderFilters() {
    return Object.entries(this.getMapping())
      .map(([k, map]) => {
        return map && <div className="popup-column">
          <div className="popup-inputContainer">
            <h2>{map.label}</h2>
            {this.renderFilter(k, map)}
          </div>
        </div>;
      });
  }

  render() {
    let translations = this.context.translations;
    return (
      <Popup
        title={translations.client_filter_title}
        actions={[
          {
            label: translations.general_delete,
            handler: this.clearFilter.bind(this),
          },
          {
            label: translations.general_filter,
            handler: this.saveFilter.bind(this),
            submit: true,
          },
        ]}
        onDismiss={this.props.onDismiss}
      >
        <div className="filterPopup">
          <div className="popup-section">
            {this.renderFilters()}
          </div>
        </div>
      </Popup>
    );
  }
}

export default FilterPopup;
