import React from "react";
import ApiClient from "../../../api-client/api-client";
import AppContext from "../../../app/app-context";
import BaseComponent from "../../../app/base-component";

import "./quotation-list-item.css";

class QuotationListItem extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      preview: null,
    };
  }

  getFilePreview() {
    let url = "/v1/WCApi/GetFilePreview";

    new ApiClient(this.context)
      .call(
        "GET",
        url,
        null,
        false,
        {},
        {
          collectionId: this.props.calculatePriceResult.file.collectionId,
          fileId: this.props.calculatePriceResult.file.fileId,
          format: "png",
        }
      )
      .then((response) => {
        this.setState({
          preview: response.json.image,
        });
      });
  }

  componentDidMount() {
    this.getFilePreview();
  }

  getPriceInCents() {
    let price = {
      'SLOW': this.props.price.slowPrice,
      'STANDARD': this.props.price.standardPrice,
      'FAST': this.props.price.fastPrice,
    }[this.props.quote.shippingTime];

    return Math.round(price * 100) || 0;
  }

  getTotalPriceInCents() {
    let price = {
      'SLOW': this.props.totalPrice.slowPrice,
      'STANDARD': this.props.totalPrice.standardPrice,
      'FAST': this.props.totalPrice.fastPrice,
    }[this.props.quote.shippingTime];

    return Math.round(price * 100) || 0;
  }

  downloadPartFile(e) {
    e.preventDefault();
    e.stopPropagation();

    let file = this.props.partFile;
    let translations = this.context.translations;

    if (!file) {
      this.context.addNotification("error", translations.notifications_download_unavailable);
      return;
    }

    this.context.addNotification("info", translations.notifications_download_started);

    let name = this.props.calculatePriceResult.fileInfo.file.name;
    let ext = this.props.partFile.extension;

    new ApiClient(this.context)
      .call("GET", file.filePath)
      .then((response) => {
        this.apiResponseAsDownload(response, `${name}.${ext}`);
      });
  }

  render() {
    let translations = this.context.translations;
    let isOrder = this.props.isOrder;
    let currencyFormatter = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' });
    let name = this.props.calculatePriceResult.fileInfo.file.name;

    return (
      <div className="quotationPopup-part">
        <div className="quotationPopup-part-header">
          <h3>
            {translations.general_part}:{" "}
            {name}
          </h3>
          {<button id="delete-btn" onClick={this.props.deletePart}>
            <svg
              id="delete-btn-svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 15.344 18"
            >
              <path
                className="delete-btn-svg-1"
                d="M13.5,13.5h1.279v7.672H13.5Z"
                transform="translate(-8.385 -7.045)"
              />
              <path
                className="delete-btn-svg-1"
                d="M20.25,13.5h1.279v7.672H20.25Z"
                transform="translate(-11.299 -7.045)"
              />
              <path
                className="delete-btn-svg-1"
                d="M4.5,6.75V8.037H5.779V20.906a1.283,1.283,0,0,0,1.279,1.287H17.287a1.283,1.283,0,0,0,1.279-1.287V8.037h1.279V6.75ZM7.057,20.906V8.037H17.287V20.906Z"
                transform="translate(-4.5 -4.193)"
              />
              <path
                className="delete-btn-svg-1"
                d="M13.5,2.25h5.115V3.529H13.5Z"
                transform="translate(-8.385 -2.25)"
              />
            </svg>
          </button>}
        </div>
        <div className="horizontalLine"></div>
        <div className="quotationPopup-part-main">
          <div className="quotationPopup-partsContainer-parts">
            <div className="quotationPopup-partsContainer-imageContainer">
              <img
                src={"data:image/png;base64," + this.state.preview}
                alt="preview"
              ></img>
            </div>
            <div className="quotationPopup-partsContainer-informationNoteContainer">
              <div className="quotationPopup-partsContainer-informationContainer">
                <div className="quotationPopup-partsContainer-general">
                  <h4>{translations["general_material"]}:</h4>
                  <div>{this.props.material}</div>
                  <h4>{translations["general_thickness"]}:</h4>
                  <div>{this.props.thickness}</div>
                  <h4>{translations["general_dimensions"]}:</h4>
                  <div>{this.props.dimensions}</div>
                </div>
                <div className="quotationPopup-partsContainer-operations">
                  <h4>{translations["general_operations"]}:</h4>
                  <div>
                    {this.getPartOperations(this.props).map((operation) => {
                      return <div key={operation}>{operation}</div>;
                    })}
                  </div>
                  {this.props.partFile && <>
                    <h4>{translations["general_partfile"]}:</h4>
                    <div>
                      <a href="#" onClick={this.downloadPartFile.bind(this)}>{name}.{this.props.partFile.extension}</a>
                    </div>
                  </>}
                </div>
                {/* <div className="quotationPopup-partsContainer-quality">
                  <h4>{translations["general_quality"]}:</h4>
                  <div>
                    {this.props.qualities.map((quality) => {
                      return <div key={quality}>{quality}</div>;
                    })}
                  </div>
                </div> */}
              </div>
              <div className="quotationPopup-partsContainer-noteContainer">
                <h4>{translations["quotation_note_title"]}:</h4>
                <input
                  disabled={isOrder}
                  name="note"
                  value={this.props.note}
                  onChange={this.props.updatePart}
                  placeholder={translations["quotation_note_text"] + "..."}
                ></input>
              </div>
            </div>
          </div>
          <div className="quotationPopup-partsContainer-price quotationPopup-part-moreTopMargin">
            {currencyFormatter.format(this.getPriceInCents() / 100)}
          </div>
          <div className="quotationPopup-partsContainer-amount quotationPopup-part-moreTopMargin">
            <input
              disabled={isOrder}
              name="amount"
              value={this.props.amount}
              onChange={this.props.updatePart}>
            </input>
          </div>
          <div className="quotationPopup-partsContainer-total quotationPopup-part-moreTopMargin">
            {currencyFormatter.format(this.getTotalPriceInCents() / 100)}
          </div>
        </div>
        {/* {this.props.subParts.map(subPart =>
          <QuotationListSubItem
            {...subPart}
            key={subPart.fileId}

            togglePartSelected={this.props.togglePartSelected}
          />
        )} */}
      </div>
    );
  }
}

export default QuotationListItem;
