import { Component } from "react";

import "./list-subitem.css";
import Checkbox from "../../../app/checkbox/checkbox";
import AppContext from "../../../app/app-context";
import ApiClient from "../../../api-client/api-client";
import { delay } from "../../../helpers";
import BaseComponent from "../../../app/base-component";

class ListSubItem extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      fileInfo: {},
      preview: null,
    };
  }

  onCheckboxChange(e) {
    let key = e.target.id;
    let ev = e.nativeEvent;
    let multi = ev.shiftKey || ev.ctrlKey || ev.metaKey || ev.altKey;

    this.props.togglePartSelected(key, multi);
  }

  onPreviewClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.setPreviewPart(this.props.fileId);
  }

  componentDidMount() {
    this.getFilePreview();
  }

  getFilePreview() {
    let url = "/v1/WCApi/GetFilePreview";

    new ApiClient(this.context)
      .call(
        "GET",
        url,
        null,
        false,
        {},
        {
          collectionId: this.props.collectionId,
          fileId: this.props.fileId,
          format: "png",
        }
      )
      .then((response) => {
        if (response.status === 206) {
          return delay(250).then(this.getFilePreview.bind(this));
        } else if (response.status === 200) {
          this.setState({
            preview: response.json.image,
          });
        }
      });
  }

  render() {
    let fileInfo = this.props.fileInfo.file || {};
    let translations = this.context.translations;
    return (
      <div className="listSubItemContainer">
        <div className="listSubItem-warning">
          {this.props.warning ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="14.098"
              viewBox="0 0 15 14.098"
            >
              <path
                id="warning"
                d="M18.236,16.973,11.922,5.246a1.185,1.185,0,0,0-2.088,0L3.52,16.973a1.186,1.186,0,0,0,1.042,1.748h12.63a1.186,1.186,0,0,0,1.044-1.748ZM10.878,16.9a.741.741,0,1,1,.741-.741A.741.741,0,0,1,10.878,16.9Zm.8-7.453-.213,4.52a.593.593,0,1,1-1.186,0l-.213-4.519a.805.805,0,0,1,.8-.84h.008a.806.806,0,0,1,.805.841Z"
                transform="translate(-3.378 -4.622)"
                fill="#e12626"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11.296"
              height="14.119"
              viewBox="0 0 11.296 14.119"
            >
              <g id="insert" transform="translate(27.046 18.619) rotate(180)">
                <path
                  id="Path_365"
                  data-name="Path 365"
                  d="M24.928,8.03H18.574L20.981,5.5l-1-1L15.75,8.736l4.236,4.236,1-1L18.574,9.442h6.354a.706.706,0,0,1,.706.706v8.472h1.412V10.148A2.118,2.118,0,0,0,24.928,8.03Z"
                  fill="#8a8ea2"
                />
              </g>
            </svg>
          )}
        </div>
        <div
          className={
            this.props.warning
              ? "listSubItem listSubItem-warningBox"
              : "listSubItem"
          }
        >
          <div className="listSubItem-header">
            <Checkbox
              id={this.props.fileId}
              selected={this.props.selected}
              onChange={this.onCheckboxChange.bind(this)}
              title={translations.general_part + ": "}
              title_two={fileInfo.name}
            />
          </div>
          <label htmlFor={this.props.fileId} className="listSubItem-main">
            <img
              className="listSubItem-image"
              alt=""
              src={"data:image/png;base64," + this.state.preview}
              onClick={this.onPreviewClick.bind(this)}
            />
            <div className="listSubItem-main-informationContainer">
              <div className="columnLeft">
                <div>
                  <span className="property">
                    {translations.general_amount}:{" "}
                  </span>
                  <span className="value">{this.props.amount}</span>
                </div>

                <div>
                  <span className="property">
                    {translations.general_material}:{" "}
                  </span>
                  <span className="value">
                    {this.props.material ? this.props.material.name : ""}
                  </span>
                </div>

                <div>
                  <span className="property">
                    {translations.general_thickness}:{" "}
                  </span>
                  <span className="value">
                    {this.props.thickness + " " + translations.general_mm}
                  </span>
                </div>
              </div>
              <div className="columnRight">
                <span className="property">
                  {translations.general_operations}:{" "}
                </span>
                <div className="operationsContainer">
                  {this.getPartOperations(this.props).map((operation) => {
                    return <div key={operation}>{operation}</div>;
                  })}
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>
    );
  }
}

export default ListSubItem;
