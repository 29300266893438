import { Component, Fragment } from "react";
import AppContext from "./app-context";
import Popup from "./popup/popup";


class BaseComponent extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.renderContent = this.render;
    this.render = this.renderContainer;

    this.state = {};
  }

  setStateAsync(state) {
    return new Promise((resolve) => this.setState(state, resolve));
  }

  apiResponseAsDownload(apiResponse, fileName = null) {
    if (apiResponse.ok) {
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(apiResponse.raw);
      a.download = fileName
        || (apiResponse.contentDisposition && apiResponse.contentDisposition['name'])
        || 'file';
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
    else {
      this.context.addNotification("error", this.context.translations.notifications_unknown_error);
    }
  }

  destroyDialog() {
    this.setState({
      dialog: null,
    });
  }

  askConfirmation(title, message, actionLabel = null) {
    let translations = this.context.translations;

    return new Promise((resolve, reject) => {
      this.setState({
        dialog: {
          title: title,
          message: message,
          actionLabel: actionLabel || translations.general_ok,
          action: () => {
            resolve(true);
            this.destroyDialog();
          },
          dismiss: () => {
            resolve(false);
            this.destroyDialog();
          }
        }
      });
    });
  }

  getPartOperations(part) {
    let translations = this.context.translations;

    return [
      this.getCuttingTechnologyMapping().find(
        c => c.tag === this.context.tenantSettings.cuttingTechnology
      ).label,
      part.bending && translations.calculate_bending,
      part.counterSinking && translations.calculate_counter_sinking,
      this.getSurfaceTreatmentMapping().find(
        (c) => c.tag === part.surfaceTreatment
      ).label,
      part.engraving && translations.calculate_engraving,
      this.getMaterialCertificateMapping().find(
        (c) => c.tag === part.materialCertificate
      ).label,
    ].filter(v => v);
  }

  getCuttingTechnologyMapping() {
    let translations = this.context.translations;

    return [
      {
        tag: "SHEETLASER",
        label: translations.cutting_technologies_sheetlaser,
      },
      {
        tag: "TUBELASER",
        label: translations.cutting_technologies_tubelaser,
      },
      {
        tag: "WATERJET",
        label: translations.cutting_technologies_waterjet,
      },
      {
        tag: "PLASMA",
        label: translations.cutting_technologies_plasma,
      },
      {
        tag: "OXYFUEL",
        label: translations.cutting_technologies_oxyfuel,
      },
    ];
  }

  getSurfaceTreatmentMapping() {
    let translations = this.context.translations;

    return [
      {
        tag: "DEBURRING",
        label: translations.surface_treatments_deburring,
      },
      {
        tag: "NONE",
        label: false,
      }
    ];
  }

  getMaterialCertificateMapping() {
    return [
      {
        tag: "NONE",
        label: false,
      },
      {
        tag: "ONE",
        label: "3.1",
      },
      {
        tag: "TWO",
        label: "3.2",
      },
    ];
  }

  renderContainer() {
    let translations = this.context.translations;

    return <Fragment>
      {this.renderContent()}
      {this.state.dialog && (
        <Popup
          className={`${this.state.dialog.className}`}
          title={this.state.dialog.title}
          actions={[
            this.state.dialog.dismiss && {
              label: translations.general_cancel,
              handler: this.state.dialog.dismiss,
            },
            {
              label: this.state.dialog.actionLabel,
              handler: this.state.dialog.action || this.destroyDialog.bind(this),
            },
          ]}
          onDismiss={this.destroyDialog.bind(this)}
        >
          <div className="popup-section">
            <div className="popup-column">
              {this.state.dialog.message}
              {this.state.dialog.content}
            </div>
          </div>
        </Popup>
      )}
    </Fragment>;
  }
}

export default BaseComponent;