
import { Component } from "react";
import AppContext from "../../app-context";

class TableActionRecalculate extends Component {
  static contextType = AppContext;

  render() {
    let translations = this.context.translations;
    let theme = this.context.theme;

    return (
      <div className="tableAction" onClick={e => { e.stopPropagation(); this.props.onClick(e); }} title={translations.quotation_recalculate_button}>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20">
          <path fill={theme.secondaryColor} d="M17.9975,10.0498A7.99883,7.99883,0,1,1,10,2a7.9225,7.9225,0,0,1,4.04651,1.125L15.3161,1.85547a.5.5,0,0,1,.84747.27563l.8003,5.06848a.50006.50006,0,0,1-.5719.5719l-5.06854-.80029a.5.5,0,0,1-.27558-.84741L12.57568,4.596A5.9374,5.9374,0,0,0,10,4a6,6,0,1,0,6,6c0-.09033-.00958-.17834-.01355-.26782Z" />
        </svg>
      </div>
    );
  }
}

export default TableActionRecalculate;
