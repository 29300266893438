import React from "react";
import AppContext from "../../app/app-context";
import BaseComponent from "../../app/base-component";

import "./upload-progress.css";

class UploadProgress extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      uploadStatus: [],
    };
  }

  render() {
    return (
      <div className="uploadProgress">
        <div className="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.113 61.259">
            <path
              id="file"
              d="M39.954,1.125H3.375V62.384H57.488V18.657ZM53.4,20.348v.176H38.088V5.209h.176ZM7.459,58.3V5.209H34v19.4H53.4V58.3Z"
              transform="translate(-3.375 -1.125)"
              fill="#2444a3"
            />
          </svg>
        </div>
        <div className="info">
          <div className="name">
            <span>{this.props.name}</span>
            <button className="cancel" onClick={this.props.cancel}>
              {this.props.complete && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="15.982"
                  viewBox="0 0 18 15.982"
                >
                  <g id="Checkmark" transform="translate(-392.482 -17.977)">
                    <path
                      id="Shape"
                      d="M7.068,16.96a1.992,1.992,0,0,1-1.57-.767L.94,10.216a1.992,1.992,0,1,1,3.14-2.45l2.988,3.966L14.885,1.79a1.992,1.992,0,1,1,3.14,2.45L8.638,16.193A1.992,1.992,0,0,1,7.068,16.96Z"
                      transform="translate(392 17)"
                      fill="#0caa1b"
                    />
                  </g>
                </svg>
              )}
              {!this.props.succeed && !this.props.complete && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 10.828 10.828"
                >
                  <g
                    id="Group_205"
                    data-name="Group 205"
                    transform="translate(-311.586 -179.586)"
                  >
                    <g
                      id="Group_87"
                      data-name="Group 87"
                      transform="translate(-0.5 -0.5)"
                    >
                      <line
                        id="Line_9"
                        data-name="Line 9"
                        x2="8"
                        y2="8"
                        transform="translate(313.5 181.5)"
                        fill="none"
                        stroke="#E1261C"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        id="Line_10"
                        data-name="Line 10"
                        x1="8"
                        y2="8"
                        transform="translate(313.5 181.5)"
                        fill="none"
                        stroke="#E1261C"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                    </g>
                  </g>
                </svg>
              )}
              {this.props.succeed && !this.props.complete && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 10.828 10.828"
                >
                  <g
                    id="Group_205"
                    data-name="Group 205"
                    transform="translate(-311.586 -179.586)"
                  >
                    <g
                      id="Group_87"
                      data-name="Group 87"
                      transform="translate(-0.5 -0.5)"
                    >
                      <line
                        id="Line_9"
                        data-name="Line 9"
                        x2="8"
                        y2="8"
                        transform="translate(313.5 181.5)"
                        fill="none"
                        stroke="#306bff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        id="Line_10"
                        data-name="Line 10"
                        x1="8"
                        y2="8"
                        transform="translate(313.5 181.5)"
                        fill="none"
                        stroke="#306bff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                    </g>
                  </g>
                </svg>
              )}
            </button>
          </div>
          <div
            className="progress"
            style={{
              paddingRight: 100 - this.props.progress * 100 + "%",
            }}
          ></div>
        </div>
      </div>
    );
  }
}

export default UploadProgress;
