import React from "react";
import AppContext from "../../app/app-context";
import BaseComponent from "../../app/base-component";
import ApiClient from "../../api-client/api-client";

import Popup from "../../app/popup/popup";

import "./upload-popup.css";
import UploadProgress from "./upload-progress";
import { arrayify, delay } from "../../helpers";
import EmailPopup from "./email-popup/email-popup";

class UploadPopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      emailPopupShown: false,
      uploadStatus: [],
      faultyCollectionIds: [],
      faultyFiles: [],
    };
  }

  uploadFiles(e) {
    let files = [...e.target.files];
    var parts = [];

    Promise.all(
      files.map(async (file) => {
        return await this.uploadFile(file, parts);
      })
    ).then(() => {
      // Another promise to get the proper data we need from the files.
      // This is done because we need to filter them out and get the thickness etc.
      Promise.all(
        parts.map(async (part) => {
          this.updateFileProgress(part.collectionId, 1, 0);

          part.hasDxfFile = part.dxfFile !== undefined;
          // Part is threeDimensional unless it is a dxffile in this map.
          part.isThreeDimensional = true;

          var fileInfo = await this.getFileInfo(
            part.collectionId,
            part.fileId,
            false
          );
          part.fileInfo = fileInfo;

          // If 2D file just make the dxfFileInfo the already retrieved file info.
          if (
            this.context.settings.twoDimensional.includes(
              fileInfo.file.extension.toLocaleLowerCase()
            )
          ) {
            // If the parent part is twoDimensional it means the file is twoDimensional. Set the flag to false.
            part.isThreeDimensional = false;
            part.dxfFileInfo = fileInfo;
          }
          // If 3D File and it has a unfolded 2D File get the file info from the unfolded 2D file.
          if (part.hasDxfFile) {
            var dxfFileInfo = await this.getFileInfo(
              part.dxfFile.collectionId,
              part.dxfFile.fileId,
              true
            );
            part.dxfFileInfo = dxfFileInfo;
          }

          this.updateFileProgress(part.collectionId, 0, 1);
          return part;
        })
      ).then((mappedParts) => {
        // The parts that should be shown in the part list:
        // 2D files (!part.isThreeDimensional)
        // Parents of 3D Compounds (part.dxfFileInfo === undefined)
        // 3D files with part types that are not 1 or 4. (2, 3, 5, 6)
        let validParts = mappedParts.filter(
          (part) =>
            (!part.isThreeDimensional ||
              part.dxfFileInfo === undefined ||
              part.dxfFileInfo.threeDimensional.partType === "_2" ||
              part.dxfFileInfo.threeDimensional.partType === "_3") &&
            !this.state.faultyCollectionIds.includes(part.collectionId)
        );
        this.props.didReceiveParts(validParts);
      });
    });
  }

  uploadFile(file, parts) {
    let formData = new FormData();
    formData.append("file", file);
    return new ApiClient(this.context)
      .call("POST", "/v1/WCApi/UploadFile", formData)
      .then(async (response) => {
        var part = {
          ...response.json,
        };

        parts.push(part);

        await this.addFileProgress(part.collectionId, file.name);
        let fileExtension = file.name.split(".").pop().toString();
        if (this.context.settings.twoDimensional.includes(fileExtension.toLocaleLowerCase())) {
          this.updateFileProgress(part.collectionId, 0, 1);
          return part;
        } else {
          return this.startUnfold(part, parts, file);
        }
      }, this);
  }

  addFileProgress(collectionId, name) {
    return this.setStateAsync((state) => ({
      uploadStatus: {
        ...state.uploadStatus,
        [collectionId]: {
          name: name,
          load: 1,
          complete: 0,
          succeed: true,
        },
      },
    }));
  }

  updateFileProgress(collectionId, load = 0, complete = 0, succeed = true) {
    return this.setStateAsync((state) => ({
      uploadStatus: {
        ...state.uploadStatus,
        [collectionId]: {
          name: state.uploadStatus[collectionId].name,
          load: state.uploadStatus[collectionId].load + load,
          complete: state.uploadStatus[collectionId].complete + complete,
          succeed: succeed,
        },
      },
    }));
  }

  // TODO: Get proper config and material
  startUnfold(part, parts, file) {
    let url = "/v1/WCApi/StartUnfold";

    let body = {
      bendConfig: "YRvAvruuZUu0O_FD5NyTBA==",
      materialNumber: 1,
      collectionId: part.collectionId,
      fileId: part.fileId,
    };

    return new ApiClient(this.context)
      .call("POST", url, body)
      .then((response) => {
        if (response.status === 204) {
          return this.getUnfold(part, parts, file);
        }
      });
  }

  getUnfold(part, parts, file) {
    let url =
      "/v1/WCApi/GetUnfold/" +
      part.collectionId +
      "/" +
      part.fileId +
      "/YRvAvruuZUu0O_FD5NyTBA==/1";

    return new ApiClient(this.context)
      .call("GET", url)
      .then((response) => {
        if (response.status === 206) {
          return delay(250).then(() => {
            return this.getUnfold(part, parts, file);
          });
        } else if (response.status === 200) {
          this.updateFileProgress(part.collectionId, 0, 1);
          return this.unfoldChildObjects(response.json, parts, part);
        }
      })
      .catch((e) => {
        this.updateFileProgress(part.collectionId, 0, 1, false);
        this.setState((state) => ({
          faultyFiles: [...state.faultyFiles, file],
          faultyCollectionIds: [
            ...state.faultyCollectionIds,
            part.collectionId,
          ],
        }));
      });
  }

  unfoldChildObjects(unfoldingResponse, parts, part) {
    if (unfoldingResponse.disassembled === null) {
      return Promise.all(
        arrayify(unfoldingResponse.unfolded).map((file) => {
          part.dxfFile = file;
          return parts;
        })
      );
    } else {
      throw new Error("This feature is not implemented yet");

      // return Promise.all(
      //   arrayify(unfoldingResponse.disassembled).map((file) => {
      //     var part = {
      //       ...file,
      //     };
      //     parts.push(part);

      //     this.updateFileProgress(part.collectionId, 1, 0);

      //     return this.startUnfold(part, parts);
      //   })
      // );
    }
  }

  getFileInfo(collectionId, fileId, isUnfoldedDxf) {
    let url = "/v1/WCApi/GetFileInfo";

    return new ApiClient(this.context)
      .call(
        "GET",
        url,
        null,
        false,
        {},
        {
          collectionId: collectionId,
          fileId: fileId,
        }
      )
      .then(async (response) => {
        if (
          this.context.settings.twoDimensional.includes(
            response.json.file.extension.toLocaleLowerCase()
          ) &&
          !response.json.status.validation2DReady &&
          !isUnfoldedDxf
        ) {
          return delay(250).then(() => {
            return this.getFileInfo(collectionId, fileId);
          });
        } else {
          return response.json;
        }
      }, this);
  }

  hideEmailPopup(file) {
    this.setState((state) => ({
      faultyFiles: state.faultyFiles.filter(
        (faultyFile) => faultyFile !== file
      ),
    }));
  }
  renderEmailPopups() {
    return this.state.faultyFiles.map((file) => (
      <EmailPopup
        key={file}
        hide={this.hideEmailPopup.bind(this, file)}
        file={file}
      />
    ));
  }

  render() {
    let translations = this.context.translations;
    let settings = this.context.settings;
    let extensions = settings.twoDimensional.concat(settings.threeDimensional);

    return (
      <>
        <Popup
          title={translations["calculate_part_upload_title"].toUpperCase()}
          onDismiss={this.props.onDismiss}
        >
          <div className="uploadPopup">
            <div className="popup-section">
              <div className="popup-column">
                <div className="progressContainer">
                  {Object.keys(this.state.uploadStatus).map((collectionId) => {
                    let item = this.state.uploadStatus[collectionId];

                    return (
                      <UploadProgress
                        key={collectionId}
                        name={item.name}
                        cancel={() => { }}
                        progress={item.load > 0 ? item.complete / item.load : 0}
                        complete={item.complete === item.load}
                        succeed={item.succeed}
                      />
                    );
                  })}
                </div>
                <label
                  className="dropzone"
                  data-label={
                    translations["calculate_part_upload_drag_and_drop"]
                  }
                >
                  <input
                    type="file"
                    onChange={this.uploadFiles.bind(this)}
                    accept={extensions.map(ext => `.${ext.toUpperCase()}`).join(', ')}
                    multiple
                  />
                </label>
              </div>
            </div>
          </div>
        </Popup>
        {this.renderEmailPopups()}
      </>
    );
  }
}

export default UploadPopup;
