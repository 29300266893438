import React, { Component } from "react";
import Checkbox from "../../app/checkbox/checkbox";
import AppContext from "../../app/app-context";
import ListItem from "./list-item/list-item";

import "./parts-list.css";
import BaseComponent from "../../app/base-component";
import ApiClient from "../../api-client/api-client";


class PartsList extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.partFileUploadRef = React.createRef();
    this.state = {
      accounts: [],
    };
  }

  componentDidMount() {
    this.getAccounts();
  }

  getAccounts() {
    if (this.context.userHasClaim('Accounts', 'View')) {
      return new ApiClient(this.context)
        .call(
          "GET",
          `/v1/Tenant/${this.context.theme.tenantId}/Account`
        )
        .then((response) => {
          let accounts = response.json;

          return this.setStateAsync({
            accounts: accounts,
          });
        });
    }
    else {
      return this.setStateAsync({
        accounts: [
          this.context.user.account
        ],
      });
    }
  }

  checkboxSelectAll(e) {
    this.props.selectAllParts();
  }

  getMainParts() {
    return this.props.parts.filter((part) => part.collectionId === part.fileId);
  }

  getSubParts(part) {
    return this.props.parts.filter(
      (subPart) =>
        subPart.collectionId === part.fileId && subPart.fileId !== part.fileId
    );
  }

  onFileChange(e) {
    let key = e.target.name;
    let val = e.target.files[0];

    this.props.onInputChange(key, val);
  }

  onSelectSettingChange(options, e) {
    let key = e.target.name;
    let val = options.find(o => o.id.toString() === e.target.value);

    this.props.onSettingChange(key, val);
  }

  onSelectChange(options, e) {
    let key = e.target.name;
    let val = options.find(o => o.id.toString() === e.target.value);

    this.props.onInputChange(key, val);
  }

  onCheckboxChange(e) {
    let key = e.target.name;
    let val = e.target.checked;

    this.props.onInputChange(key, val);
  }

  onInputChange(e) {
    let key = e.target.name;
    let val = e.target.value;

    this.props.onInputChange(key, val);
  }

  render() {
    let translations = this.context.translations;
    let allSelected = this.props.parts.every((p) => p.selected);
    let isThreeDimensional = this.props.selectedParts.some(
      (p) => p.isThreeDimensional
    );

    let parts = this.props.selectedParts;
    let uniqueValuesForProp = (selector, filterEmpty = false) => parts
      .map(selector)
      .filter((v, i, s) => (!filterEmpty || !!v) && s.indexOf(v) === i);
    let singleOrDefault = (array, fallback) =>
      array.length === 1 && array[0] || fallback;

    let amounts = uniqueValuesForProp(p => p.amount);
    let materials = uniqueValuesForProp(p => p.material);
    let materialThicknesses = uniqueValuesForProp(p => p.materialThickness);
    let surfaceTreatments = uniqueValuesForProp(p => p.surfaceTreatment);
    let bendings = uniqueValuesForProp(p => p.bending);
    let hasBendingss = uniqueValuesForProp(p => p.hasBendings);
    let counterSinks = uniqueValuesForProp(p => p.counterSinking);
    let hasCounterSinkss = uniqueValuesForProp(p => p.hasCounterSinks);
    let engravings = uniqueValuesForProp(p => p.engraving);
    let materialCertificates = uniqueValuesForProp(p => p.materialCertificate);
    let partFiles = uniqueValuesForProp(p => p.partFile);

    let amount = singleOrDefault(amounts, '');
    let material = singleOrDefault(materials, { id: 0xDEADBEEF });
    let materialThickness = singleOrDefault(materialThicknesses, { thickness: 0 });
    let surfaceTreatment = singleOrDefault(surfaceTreatments, false);
    let bending = singleOrDefault(bendings, false);
    let hasBendings = singleOrDefault(hasBendingss, false);
    let counterSink = singleOrDefault(counterSinks, false);
    let hasCounterSinks = singleOrDefault(hasCounterSinkss, false);
    let engraving = singleOrDefault(engravings, false);
    let materialCertificate = singleOrDefault(materialCertificates, false);
    let partFile = singleOrDefault(partFiles, null);

    let thicknessOptions = this.props.thicknesses.map(t => ({
      id: t.thickness,
      ...t,
    }));
    let materialOptions = materialThickness.materials || [];

    return (
      <div className="partsList">
        <div className="partsList-header">
          <button
            className="generalButton"
            onClick={this.props.toggleUploadPopUp}
          >
            <svg
              id="upload-svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 18"
            >
              <g
                data-name="Ellipse 1"
                fill="none"
                stroke="#fff"
                strokeWidth="1"
              >
                <circle cx="9" cy="9" r="9" stroke="none" />
                <circle cx="9" cy="9" r="8.5" fill="none" />
              </g>
              <path
                data-name="Path 301"
                d="M.044-10.311v3.333h3.35v1.77H.044v3.333H-1.984V-5.208h-3.35v-1.77h3.35v-3.333Z"
                transform="translate(10.243 15.22)"
                fill="#fff"
              />
            </svg>

            {translations["general_upload"]}
          </button>

          {this.props.parts.length > 0 && (
            <button
              className="generalButton"
              onClick={this.props.toggleImportOrderLinePopUp}
            >
              <svg
                id="upload-svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 18"
              >
                <g
                  data-name="Ellipse 1"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="1"
                >
                  <circle cx="9" cy="9" r="9" stroke="none" />
                  <circle cx="9" cy="9" r="8.5" fill="none" />
                </g>
                <path
                  data-name="Path 301"
                  d="M.044-10.311v3.333h3.35v1.77H.044v3.333H-1.984V-5.208h-3.35v-1.77h3.35v-3.333Z"
                  transform="translate(10.243 15.22)"
                  fill="#fff"
                />
              </svg>

              {"Import"}
            </button>
          )}
        </div>

        {this.props.parts.length > 0 ? (
          <div className="partsList-container">
            <div className="partsList-controlsContainer">
              <Checkbox
                id="selectAll"
                onChange={this.checkboxSelectAll.bind(this)}
                selected={allSelected}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.223"
                  height="7.929"
                  viewBox="0 0 13.223 7.929"
                >
                  <path
                    id="Shape"
                    d="M13.23,1.336A1.325,1.325,0,0,0,10.969.4L6.62,4.748,2.272.4A1.327,1.327,0,1,0,.4,2.272L5.684,7.548a1.325,1.325,0,0,0,1.873,0l5.289-5.276A1.325,1.325,0,0,0,13.23,1.336Z"
                    transform="translate(-0.007 -0.007)"
                    fill="#1b1b1b"
                  />
                </svg>
              </Checkbox>

              <button id="delete-btn" onClick={this.props.deleteSelection}>
                <svg
                  id="delete-btn-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 15.344 18"
                >
                  <path
                    className="delete-btn-svg-1"
                    d="M13.5,13.5h1.279v7.672H13.5Z"
                    transform="translate(-8.385 -7.045)"
                  />
                  <path
                    className="delete-btn-svg-1"
                    d="M20.25,13.5h1.279v7.672H20.25Z"
                    transform="translate(-11.299 -7.045)"
                  />
                  <path
                    className="delete-btn-svg-1"
                    d="M4.5,6.75V8.037H5.779V20.906a1.283,1.283,0,0,0,1.279,1.287H17.287a1.283,1.283,0,0,0,1.279-1.287V8.037h1.279V6.75ZM7.057,20.906V8.037H17.287V20.906Z"
                    transform="translate(-4.5 -4.193)"
                  />
                  <path
                    className="delete-btn-svg-1"
                    d="M13.5,2.25h5.115V3.529H13.5Z"
                    transform="translate(-8.385 -2.25)"
                  />
                </svg>
              </button>
            </div>

            <div className="partsList-mainContainer">
              <div className="partsList-list">
                {this.getMainParts().map((part) => (
                  <ListItem
                    {...part}
                    subParts={this.getSubParts(part)}
                    key={part.fileId}
                    instantValidation={this.props.instantValidation}
                    togglePartSelected={this.props.togglePartSelected}
                    setPreviewPart={this.props.setPreviewPart}
                  />
                ))}
              </div>
              <div className="partsList-manufactureSettingsContainer">
                <div className="manufactureContainer">
                  <span>{translations["general_account"]}</span>
                  <select
                    name="account"
                    onChange={this.onSelectSettingChange.bind(this, this.state.accounts)}
                    value={this.props.account.id}
                  >
                    {this.state.accounts.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="title">
                  {translations["calculate_manufacture_settings"]}
                </div>

                <div className="manufactureContainer">
                  <span>{translations["general_amount"]}</span>
                  <input
                    name="amount"
                    type="number"
                    placeholder={translations["general_amount"] + "..."}
                    onChange={this.onInputChange.bind(this)}
                    value={amount}
                  ></input>
                  <span>{translations["general_thickness"]}</span>
                  <select
                    name="materialThickness"
                    onChange={this.onSelectChange.bind(this, thicknessOptions)}
                    value={materialThickness.thickness}
                    disabled={parts.length === 0 || isThreeDimensional}
                  >
                    <option key={0} value={0}>
                      {translations["general_thickness"] + "..."}
                    </option>
                    {thicknessOptions.map((option) => (
                      <option key={option.thickness} value={option.thickness}>
                        {`${option.thickness} ${translations.general_mm}`}
                      </option>
                    ))}
                  </select>
                  <span>{translations["general_material"]}</span>
                  <select
                    name="material"
                    onChange={this.onSelectChange.bind(this, materialOptions)}
                    value={material.id}
                    disabled={parts.length === 0}
                  >
                    <option key={0xDEADBEEF} value={0xDEADBEEF}>
                      {translations["general_material"] + "..."}
                    </option>
                    {materialOptions.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="title">
                  {translations["general_operations"]}
                </div>

                {this.props.selectedParts.length > 0 && (
                  <div className="operationsContainer">
                    <Checkbox
                      disabled={true}
                      title={
                        this.getCuttingTechnologyMapping().find(
                          c => c.tag === this.context.tenantSettings.cuttingTechnology
                        ).label
                      }
                      id="cuttingTechnology"
                      selected={true}
                    />

                    {hasBendings && (
                      <Checkbox
                        title={translations.calculate_bending}
                        selected={bending}
                        id="bending"
                        onChange={this.onCheckboxChange.bind(this)}
                      />
                    )}

                    {hasCounterSinks && (
                      <Checkbox
                        title={translations.calculate_counter_sinking}
                        selected={counterSink}
                        id="counterSinking"
                        onChange={this.onCheckboxChange.bind(this)}
                      />
                    )}

                    <select
                      name="surfaceTreatment"
                      onChange={this.onInputChange.bind(this)}
                      value={surfaceTreatment}
                    >
                      <option key={"NONE"} value={"NONE"}>
                        {translations.calculate_surface_treatment + "..."}
                      </option>
                      {this.getSurfaceTreatmentMapping()
                        .filter(o => o.label)
                        .map((surfaceTreatment) => (
                          <option
                            key={surfaceTreatment.tag}
                            value={surfaceTreatment.tag}
                          >
                            {surfaceTreatment.label}
                          </option>
                        ))}
                    </select>
                    <Checkbox
                      title={translations.calculate_engraving}
                      selected={engraving}
                      id="engraving"
                      onChange={this.onCheckboxChange.bind(this)}
                    />
                    <select
                      name="materialCertificate"
                      onChange={this.onInputChange.bind(this)}
                      value={materialCertificate}
                    >
                      <option key={"NONE"} value={"NONE"}>
                        {translations.calculate_material_certificate + "..."}
                      </option>
                      {this.getMaterialCertificateMapping()
                        .filter(o => o.label)
                        .map((materialCertificate) => (
                          <option
                            key={materialCertificate.tag}
                            value={materialCertificate.tag}
                          >
                            {materialCertificate.label}
                          </option>
                        ))}
                    </select>
                  </div>
                )}

                {parts.length === 1 &&
                  <div className="buttonContainer">
                    <input
                      type="file"
                      name="partFile"
                      className="partFileUpload"
                      ref={this.partFileUploadRef}
                      onChange={this.onFileChange.bind(this)}
                    />
                    <button className="generalButton" onClick={() => this.partFileUploadRef.current.click()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.475"
                        height="17.519"
                        viewBox="0 0 15.475 17.519"
                      >
                        <path
                          id="file"
                          d="M13.836,1.125H3.375V18.644H18.85V6.139Zm3.846,5.5v.05H13.3V2.293h.05ZM4.543,17.476V2.293h7.592V7.841h5.548v9.636Z"
                          transform="translate(-3.375 -1.125)"
                          fill="#fff"
                        />
                      </svg>
                      {translations["calculate_add_file_button"]}
                    </button>
                    <span className="partFileLabel">{partFile && partFile.name}</span>
                  </div>
                }
              </div>
            </div>
          </div>
        ) : (
          <div className="partsList-container-noUpload">
            <p className="partsList-noUpload">
              {translations["calculate_part_upload_call_to_action"]}...
            </p>
          </div>
        )}
        {this.props.parts.length > 0 && (
          <div className="partsList-footer">
            <button
              className="generalButton"
              onClick={this.props.calculateCosts}
            >
              {translations["calculate_calculate_costs_button"]}
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default PartsList;
