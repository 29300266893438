import { Component } from 'react';

import './checkbox.css';


class Checkbox extends Component {
  onChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  render() {
    return (
      <label className={`checkboxContainer ${this.props.disabled && 'disabled'}`} htmlFor={this.props.id}>
        {this.props.title}
        {this.props.title_two && <span className="listItem-header-itemName">{this.props.title_two}</span>}
        <input id={this.props.id} name={this.props.id} type="checkbox" onChange={this.onChange.bind(this)} checked={this.props.selected} disabled={this.props.disabled}></input>
        <span className="checkmark"></span>

        {this.props.children}
      </label>
    );
  }
}

export default Checkbox;