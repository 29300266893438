import React, { Component } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

class SvgPreview extends Component {
  render() {
    return (
      <div className="modelPreview-svg">
        <TransformWrapper>
          <TransformComponent>
            <img alt="" src={`data:image/svg+xml;base64,${this.props.data}`} />
          </TransformComponent>
        </TransformWrapper>
      </div>
    );
  }
}
export default SvgPreview;
